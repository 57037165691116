import {
  Dialog,
  DialogContent,
  Grid,
  autocompleteClasses,
} from '@mui/material';
import { Box } from '@mui/system';
import MediumTypography from '../../components/formlib/MediumTypography';
import Textinput from '../../components/formlib/Textinput';
import { employeeTypes } from '../../services/Constant';
import SelectComponent from '../../components/formlib/SelectComponent';
import { zipcodeValidation } from '../../utils/formValidationUtil';
import AutocompleteSearch from '../../components/formlib/AutocompleteSearch';
import { OptionType, employeeRoles } from '../../utils/type';
import MultipleAutocompleteSearch from '../../components/formlib/MultipleAutocompleteSearch';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { EmployeeFormData } from './types';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router';

import { ReactComponent as Cross } from '../../assets/images/x.svg';
import { EMPLOYEES_ROUTE_NAME } from '../../routes/Routing';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import { useRef } from 'react';

// const superProgramId: string = localStorage.getItem('superProgramId') || '{}';

interface OnBoardingEmployeeModalProps {
  modalVisible: boolean;
  employeeData: EmployeeFormData;
  employeeList: OptionType[];
  groups: OptionType[];
  handleSubmission: (values: EmployeeFormData) => void;
}

export interface GroupType {
  id: string;
  groupName: string;
  createdBy?: string;
  level?: number;
}

const validationSchema = yup.object().shape({
  employeeStartDate: yup.string().required('joiningDate.fieldEmptyMessage'),
  workingHours: yup
    .number()
    .max(120, 'workingHoursErrorMessage')
    .required('CommunicationLogPage.fieldEmptyMessage'),
  reportingTo: yup
    .object()
    .shape({
      id: yup.string(),
      label: yup.string(),
    })
    .required('CommunicationLogPage.fieldEmptyMessage')
    .nullable(),
  employeeRole: yup.string().required('employeeRole.fieldEmptyMessage'),
  employeeType: yup.string().required('CommunicationLogPage.fieldEmptyMessage'),
  group: yup
    .object()
    .shape({
      id: yup.string().required('CommunicationLogPage.fieldEmptyMessage'),
      groupName: yup
        .string()
        .required('CommunicationLogPage.fieldEmptyMessage'),
    })
    .nullable(),
});

const OnBoardingEmployeeModal = ({
  modalVisible,
  employeeData,
  employeeList,
  groups,
  handleSubmission,
}: OnBoardingEmployeeModalProps) => {
  const navigate = useNavigate();

  const handleOnBoardSubmit = (values: EmployeeFormData) => {
    handleSubmission({
      ...values,
      employeeStartDate: dayjs(values.employeeStartDate).format('MM/DD/YYYY'),
    });
  };
  const formikRef = useRef<FormikProps<EmployeeFormData>>(null);
  return (
    <Box component="div">
      <Formik
        innerRef={formikRef}
        initialValues={{
          ...employeeData,
          employeeStartDate: employeeData.employeeStartDate
            ? dayjs(employeeData.employeeStartDate)
            : dayjs().format('MM/DD/YYYY'),
        }}
        enableReinitialize
        validateOnChange
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleOnBoardSubmit(values);
        }}
      >
        {({ values, setFieldValue, errors, touched, handleSubmit }) => (
          <Dialog
            open={modalVisible}
            keepMounted
            onClose={() => ({})}
            fullWidth
            aria-describedby="alert-dialog-slide-description"
            maxWidth={'lg'}
          >
            <DialogContent sx={{ padding: '16px 24px' }}>
              <Box className="flex__ alignItemCenter justifyContentSpaceBtw">
                <MediumTypography
                  labelid={'onBoardingModalTitle'}
                  defaultlabel="Onboarding"
                  fontSize="24px"
                  fontweight={600}
                  textColor="#2a4241"
                />
                <Cross
                  style={{
                    cursor: 'pointer',
                    marginLeft: '10px',
                  }}
                  onClick={() => navigate(EMPLOYEES_ROUTE_NAME)}
                />
              </Box>
              <Box
                className="formCardview pl-sm pr-sm pt-lg pb-lg mt-md gray3 borderRadius4"
                sx={{
                  border: '1px solid #00C6B8',
                  background: '#ECF9F8',
                }}
              >
                <Grid container spacing={3} className="flex__">
                  <Grid item xs={4}>
                    <Textinput
                      name="firstName"
                      labelid="AddNewReferralDetails.First"
                      defaultlabelid="First Name"
                      Value={values.firstName}
                      inputProps={{ readOnly: true }}
                      className="bg__white"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Textinput
                      name="middleName"
                      labelid="Clientpage.Middle"
                      defaultlabelid="Middle Name"
                      Value={values.middleName}
                      inputProps={{ readOnly: true }}
                      disabled={true}
                      className="bg__white"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Textinput
                      name="lastName"
                      labelid="AddNewReferralDetails.Last"
                      defaultlabelid="Last Name"
                      Value={values.lastName}
                      inputProps={{ readOnly: true }}
                      className="bg__white"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} className="flex__ mt-xs">
                  <Grid item xs={4}>
                    <Textinput
                      name="email"
                      labelid="Contacts.email"
                      defaultlabelid="Email"
                      Value={values.email}
                      inputProps={{ readOnly: true }}
                      className="bg__white"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SelectComponent
                      name="employeeType"
                      labelid="employeeTypeText"
                      defaultlabelid="Employee Type"
                      names={employeeTypes}
                      value={
                        values.employeeType !== null ? values.employeeType : ''
                      }
                      handleChange={(e) => {
                        setFieldValue('employeeType', e);
                      }}
                      Required={true}
                    />
                    {errors.employeeType && touched.employeeType && (
                      <MediumTypography
                        className="errorText-md"
                        labelid={errors.employeeType}
                        defaultlabel="Field should not be empty"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Textinput
                      name="workingHoursPerWeek"
                      labelid="workingHoursPerWeek"
                      defaultlabelid="Working Hours Per Week"
                      inputProps={{ maxLength: 3 }}
                      Value={values.workingHours.toString()}
                      handlechange={(text) => {
                        if (text.length < 4) {
                          const zip = zipcodeValidation(text);
                          if (zip.length > 0) {
                            setFieldValue('workingHours', parseInt(zip));
                          } else {
                            setFieldValue('workingHours', '');
                          }
                        }
                      }}
                      className="bg__white"
                      Required
                    />
                    {errors.workingHours && touched.workingHours && (
                      <MediumTypography
                        className="errorText-md"
                        labelid={errors.workingHours}
                        defaultlabel="Field should not be empty"
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3} className="flex__ mt-xs">
                  <Grid item xs={4}>
                    <AutocompleteSearch
                      className="consentAutoSearch"
                      labelId={'reportingToText'}
                      defaultId="Reporting To"
                      Required
                      optionHintId={'noemployeeDropdownTextId'}
                      sxProps={{
                        [`& .${autocompleteClasses.popupIndicator}`]: {
                          transform: 'none',
                        },
                        backgroundColor: 'white',
                      }}
                      data={employeeList}
                      selectedValue={values.reportingTo as OptionType}
                      onSelectItem={(value: OptionType | null) => {
                        if (value) {
                          setFieldValue('reportingTo', value);
                        } else {
                          setFieldValue('reportingTo', '');
                        }
                      }}
                      disableLabel={true}
                    />
                    {errors.reportingTo && touched.reportingTo && (
                      <MediumTypography
                        className="errorText-md"
                        labelid={errors.reportingTo}
                        defaultlabel="Field should not be empty"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <MultipleAutocompleteSearch
                      labelId="employeeRoleText"
                      defaultId="Employee Role"
                      data={employeeRoles}
                      type="string"
                      required
                      onSelectItem={(value: OptionType[] | null) => {
                        if (value) {
                          const selectedContactIds = value.map((emp) => emp.id);
                          setFieldValue(
                            'employeeRole',
                            selectedContactIds.toString(),
                          );
                        }
                      }}
                      disableLabel={true}
                      optionHintId={'No Employee Role found'}
                      sxProps={{
                        [`& .${autocompleteClasses.popupIndicator}`]: {
                          transform: 'none',
                          marginTop: '2px',
                        },
                        backgroundColor: 'white',
                      }}
                      value={values.employeeRole
                        .split(',') // Split the string by comma
                        .map((emp) => ({
                          id: emp,
                          label:
                            employeeRoles.find((empRole) => empRole.id === emp)
                              ?.label || '', // Set label to empty string if not found
                        }))
                        .filter(
                          (option): option is { id: string; label: string } =>
                            option.label !== '', // Filter out options with empty labels
                        )}
                    />
                    {errors.employeeRole && touched.employeeRole && (
                      <MediumTypography
                        className="errorText-md"
                        labelid={errors.employeeRole}
                        defaultlabel="Field should not be empty"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <AutocompleteSearch
                      className="consentAutoSearch"
                      labelId={'selectGroupText'}
                      defaultId="Select Group"
                      optionHintId={'noOptionFound'}
                      sxProps={{
                        [`& .${autocompleteClasses.popupIndicator}`]: {
                          transform: 'none',
                        },
                        backgroundColor: 'white',
                      }}
                      data={
                        groups
                        // JSON.parse(
                        //   localStorage.getItem('selectedProgram') || '{}',
                        // ).id === superProgramId
                        //   ? groups.map((group) => ({
                        //       id: group.id,
                        //       label: group.groupName,
                        //     }))
                        //   : groups
                        //       .filter(
                        //         (group) =>
                        //           group.createdBy !== 'Default' ||
                        //           group.level !== 1,
                        //       )
                        //       .map((group) => ({
                        //         id: group.id,
                        //         label: group.groupName,
                        //       }))
                      }
                      selectedValue={{
                        id: values.group.id ?? '',
                        label: values.group.groupName ?? '',
                      }}
                      onSelectItem={(value: OptionType | null) => {
                        if (value) {
                          setFieldValue('group', {
                            id: value.id,
                            groupName: value.label,
                          });
                        } else {
                          setFieldValue('group', { id: '', groupName: '' });
                        }
                      }}
                      disableLabel={true}
                      Required
                    />
                    {errors.group && touched.group && (
                      <MediumTypography
                        className="errorText-md"
                        labelid={errors.group.id}
                        defaultlabel="Field should not be empty"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <DatePickerComponent
                      className="bg__white"
                      labelid="editEmpjoinDateText"
                      defaultlabelid="Join Date"
                      value={dayjs(values.employeeStartDate) as Dayjs}
                      required={true}
                      disableFuture={false}
                      handlechange={(date: Dayjs | null) => {
                        if (date === null) {
                          formikRef.current?.setValues({
                            ...values,
                            employeeStartDate: null,
                            employeeEndDate: null,
                          });
                        } else {
                          formikRef.current?.setValues({
                            ...values,
                            employeeStartDate: dayjs(date).format('MM/DD/YYYY'),
                            employeeEndDate: null,
                          });
                        }
                      }}
                    />
                    {errors.employeeStartDate && touched.employeeStartDate && (
                      <MediumTypography
                        className="errorText-md"
                        labelid={errors.employeeStartDate}
                        defaultlabel="Field should not be empty"
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  className="flex__ justifyContent-FlexEnd pt-lg "
                  container
                  direction="row"
                  alignItems="right"
                >
                  <Grid item>
                    <ButtonComponent
                      className="btn-primary btn-cancel  mr-md"
                      variantType="outlined"
                      labelId="cancelText"
                      defaultLabelId="Cancel"
                      onClick={() => {
                        navigate(EMPLOYEES_ROUTE_NAME);
                      }}
                    />

                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      labelId={'saveText'}
                      defaultLabelId="Save"
                      onClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
          </Dialog>
        )}
      </Formik>
    </Box>
  );
};

export default OnBoardingEmployeeModal;

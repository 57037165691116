import { Box } from '@mui/material';
import React, { useState } from 'react';

interface TextBoxproptype {
  name?: string;
  Value: string | null;
  handlechange?: (value: string) => void;
  maxLength?: number;
  placeholder?: string;
  height?: string;
  rows?: number;
  disabled?: boolean;
  Required?: boolean;
  placeholderBackgroundColor?: string;
}

const CommentTextbox: React.FC<TextBoxproptype> = (props) => {
  const {
    rows,
    height,
    placeholder,
    maxLength,
    name,
    Value,
    disabled,
    handlechange,
    Required,
    placeholderBackgroundColor,
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const getBackgroundColor = (pBgColor?: string) => {
    if (!isFocused) {
      if (Value !== null && Value?.length !== 0 && pBgColor) {
        return pBgColor;
      }
      return 'white';
    }
    if (Value !== null && pBgColor) {
      return pBgColor;
    } else {
      return 'white';
    }
  };

  return (
    <Box style={{ position: 'relative', width: '100%', marginTop: '8px' }}>
      {placeholder && (
        <label
          style={{
            position: 'absolute',
            top: isFocused || Value ? '-8px' : '12px',
            left: '10px',
            fontSize: isFocused || Value ? '12px' : '14px',
            color: isFocused ? '#00C6B8' : '#97A6A5',
            transition: 'border-color 0.2s ease-out',
            pointerEvents: 'none',
            zIndex: 1,
            backgroundColor: getBackgroundColor(placeholderBackgroundColor),
            padding: '0 4px',
            fontFamily: 'Lato-Regular',
          }}
        >
          {`${placeholder}${Required ? '*' : ''} `}
        </label>
      )}
      <textarea
        className="textarea"
        rows={rows}
        name={name}
        maxLength={maxLength}
        disabled={disabled}
        value={Value ?? ''}
        onChange={(event) => {
          if (handlechange !== undefined) {
            handlechange(event.target.value.trimStart());
          }
        }}
        required={Required}
        onFocus={(e) => {
          e.target.style.borderColor = '#00C6B8';
          setIsFocused(true);
        }}
        onBlur={(e) => {
          e.target.style.borderColor = '#ccc';
          setIsFocused(false);
        }}
        style={{
          width: '100%',
          fontFamily: 'Lato-Regular',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '19px',
          height: height ?? 'auto',
          minHeight: height,
          padding: '12px 16px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          transition: 'border-color 0.3s ease-out',
          resize: 'none',
          outline: 'none',
          backgroundColor: 'white',
        }}
      />
    </Box>
  );
};

export default CommentTextbox;

import { FC } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Client } from '../../utils/type';
import TextField from '@mui/material/TextField/TextField';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import { FormattedMessage } from 'react-intl';
import { Employee } from '../../services/configApi/employees/employeeServices';
import Tooltip from '@mui/material/Tooltip';

interface DropdownFieldProps {
  labelId: string;
  defaultId: string;
  optionHintId?: string;
  disabled?: boolean;
  data: MultiSelectOptioinType[];
  onSelectItem: (value: MultiSelectOptioinType[]) => void;
  hideOption?: string | null;
  value?: MultiSelectOptioinType[];
  highlightSelectedValues?: string[];
  highlightSelectedValuesTooltipLabelId?: string;
  required?: boolean;
}
export type MultiSelectOptioinType = Employee | Client;

const MultiSelectAutocompleteSearch: FC<DropdownFieldProps> = ({
  labelId,
  defaultId,
  data,
  onSelectItem,
  hideOption,
  value,
  disabled,
  optionHintId,
  highlightSelectedValues,
  highlightSelectedValuesTooltipLabelId,
  required,
}) => {
  const isEmployee = (option: MultiSelectOptioinType): option is Employee => {
    return 'name' in option;
  };

  const isOptionEqualToValue = (
    option: MultiSelectOptioinType,
    valueSelected: MultiSelectOptioinType,
  ) => {
    if (typeof valueSelected.id === 'number') {
      return option?.id?.toString() === valueSelected?.id?.toString();
    } else {
      return option.id === valueSelected.id;
    }
  };

  const getDisabledState = (option: MultiSelectOptioinType) => {
    if (option.allowDelete === undefined) {
      return false;
    }
    if (option.allowDelete === null || option.allowDelete) {
      return false;
    }
    return true;
  };

  const getEmployeeName = (
    option: Employee,
    showingSelectedValues: boolean,
  ): string => {
    let employeeName = '';
    employeeName = option.firstName ? option.firstName : '';
    if (
      option.lastName !== undefined &&
      option.lastName != null &&
      option.lastName.length !== 0
    ) {
      if (showingSelectedValues) {
        employeeName = employeeName
          .concat(' ')
          .concat(option.lastName.substring(0, 1).toUpperCase());
      } else {
        employeeName = employeeName.concat(' ').concat(option.lastName);
      }
    }

    if (!showingSelectedValues && option.email && option.email != null) {
      employeeName = employeeName.concat(' - ').concat(option.email);
    }
    return employeeName;
  };

  const getClientName = (
    option: Client,
    showingSelectedValues: boolean,
  ): string => {
    let clientName = option.firstName;
    if (
      option.middleName !== undefined &&
      option.middleName !== null &&
      option.middleName.length !== 0
    ) {
      clientName = clientName
        .concat(' ')
        .concat(option.middleName.substring(0, 1).toUpperCase());
    }
    clientName = clientName.concat(' ').concat(option.lastName);

    if (!showingSelectedValues && option.dateOfBirth !== '') {
      clientName = clientName.concat(' - ').concat(option.dateOfBirth ?? '');

      if (option.sex && option.sex.length != 0) {
        clientName = clientName
          .concat(', ')
          .concat(option.sex.substring(0, 1).toUpperCase());
      }
    }
    return clientName;
  };

  const formatLabel = (
    option: MultiSelectOptioinType,
    showingSelectedValues: boolean,
  ) => {
    if (isEmployee(option)) {
      return getEmployeeName(option, showingSelectedValues);
    } else {
      return getClientName(option, showingSelectedValues);
    }
  };
  return (
    <Autocomplete
      sx={{
        '& .MuiInputLabel-root': {
          fontFamily: 'Lato-Regular',
          fontSize: '14px',
        },
        '& .MuiOutlinedInput-input': {
          fontFamily: 'Lato-Regular',
          fontSize: '14px',
        },
        '& label.Mui-focused': {
          color: '#00C6B8',
        },
        '& .MuiOutlinedInput-root': {
          padding: '2px',
          '&.Mui-focused fieldset': {
            borderColor: '#00C6B8',
            borderWidth: '1px',
          },
          '&:hover fieldset': {
            borderColor: '#00938e',
          },
        },

        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#00938e',
          },
        },
        '& .MuiAutocomplete-inputRoot': {
          height: '36px',
        },
      }}
      multiple
      options={data}
      value={value}
      disabled={disabled}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionDisabled={(option) => {
        if (hideOption) {
          return option.id === hideOption;
        } else {
          return false;
        }
      }}
      getOptionLabel={(options) => formatLabel(options, false)}
      id="combo-box-dropdown"
      filterSelectedOptions
      noOptionsText={
        <FormattedMessage
          id={optionHintId}
          defaultMessage={'No options found'}
        />
      }
      popupIcon={<KeyboardArrowDownIcon />}
      onChange={(
        _: React.SyntheticEvent,
        newValue: MultiSelectOptioinType[] | null,
      ) => {
        let filteredData: MultiSelectOptioinType[] = [];
        if (value) {
          filteredData = value.filter((item) => item.allowDelete === false);
        }
        if (newValue !== null) {
          newValue.forEach((item) => {
            if (
              item.allowDelete === undefined ||
              item.allowDelete === true ||
              item.allowDelete === null
            ) {
              filteredData.push(item);
            }
          });
          onSelectItem(filteredData);
        }
      }}
      ListboxProps={{
        sx: {
          fontFamily: 'Lato-Regular',
        },
      }}
      renderInput={(params) => (
        <TextField
          className="inputSelectFormLabel"
          {...params}
          label={<FormattedMessage id={labelId} defaultMessage={defaultId} />}
          variant="outlined"
          required={required}
        />
      )}
      renderOption={(props, option) => (
        <li key={option.id} {...props}>
          {formatLabel(option, false)}
        </li>
      )}
      renderTags={(selectedValues: MultiSelectOptioinType[], getTagProps) =>
        selectedValues.map((option, index) => {
          return (
            <>
              {highlightSelectedValues?.includes(option.id as string) &&
              highlightSelectedValuesTooltipLabelId ? (
                <Tooltip
                  title={
                    <FormattedMessage
                      id={highlightSelectedValuesTooltipLabelId}
                    />
                  }
                >
                  <Chip
                    variant="outlined"
                    label={formatLabel(option, true)}
                    style={{
                      borderColor: '#EB4C60',
                      borderWidth: '1px',
                      color: '#EB4C60',
                    }}
                    {...getTagProps({ index })}
                    disabled={getDisabledState(option)}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={formatLabel(option, false)}>
                  <Chip
                    variant="outlined"
                    label={formatLabel(option, true)}
                    style={{
                      borderColor: 'gray',
                      borderWidth: '1px',
                      paddingTop: '2px',
                    }}
                    size="small"
                    {...getTagProps({ index })}
                    disabled={getDisabledState(option)}
                  />
                </Tooltip>
              )}
            </>
          );
        })
      }
    />
  );
};

export default MultiSelectAutocompleteSearch;

import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import {
  AlertColor,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import { Box } from '@mui/system';
import { OptionType } from '../../utils/type';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import ConsentTableRow from './ConsentTableRow';
import MediumTypography from '../../components/formlib/MediumTypography';
import {
  getConsentDetails,
  ConsentListData,
  getConsentList,
  downloadConsent,
} from '../../services/configApi/forms/Consent/ConsentServices';
import { useNavigate } from 'react-router';
import { CONSENT_RELEASE_INFO, FACESHEET } from '../../routes/Routing';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import TitleText from '../../components/formlib/TitleText';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import SingleLine from '../../components/formlib/SingleLine';
import { ReactComponent as Close } from '../../assets/images/x.svg';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import dayjs from 'dayjs';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import EmptyScreen from '../../components/shared/EmptyScreen';
import AppPagination from '../../components/shared/AppPagination';
import ShareDownloadModal from '../../components/formlib/modal/ShareDownloadModal';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';
import { getFullNameWithMiddleName } from '../../utils/nameUtils';
import { ConsentContext, ConsentContextType } from './ConsentProvider';
import { checkOffline } from '../../services/Offline';

type Order = 'asc' | 'desc';

export const consentType: OptionType[] = [
  {
    id: 'GRI',
    label: 'General Release of Information',
  },
  {
    id: 'Assessment',
    label: 'Consent for Evaluation / Assessment',
  },
  {
    id: 'Telehealth',
    label: 'Consent for Telehealth',
  },
  {
    id: 'SavingsAcc',
    label: 'Health Savings Account Alert',
  },
  {
    id: 'SspShare',
    label: 'SSP Consent to Share',
  },
  {
    id: 'SspCommunicate',
    label: 'SSP Consent to Communicate',
  },
  {
    id: 'CommunicateWithMedical',
    label: 'Consent to communicate with Medical Professionals using THOM Email',
  },
  {
    id: 'CommunicateWithFamily',
    label:
      'Consent to communicate with Family using THOM email and ThomConnect',
  },
  {
    id: 'NoticeOfPrivacyPractices',
    label: 'Notice of Privacy Practices',
  },
  {
    id: 'LegalReleaseofRecords',
    label: 'Legal Release of Records',
  },
  {
    id: 'PriorWrittenNoticeForm',
    label: 'Prior Written Notice Form',
  },
  {
    id: 'ConsentForChangeServiceDeliveryPlan',
    label: 'IFSP Consent',
  },
];

interface Data {
  consentName: string;
  consentVersion: number;
  consentDate: string;
  expirationDate: string;
  status: string;
  permissionGrantedTo: string;
  actions: string;
}

const headCells: HeadCell<Data>[] = [
  {
    id: 'consentName',
    labelId: 'ConsentForms.listHeaderConsents',
    defaultLabelId: 'Consent Name',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'consentDate',
    labelId: 'ConsentForms.listHeaderConsentDate',
    defaultLabelId: 'Consent Date',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'expirationDate',
    labelId: 'ConsentForms.listHeaderExpiryDate',
    defaultLabelId: 'Expiry Date',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'status',
    labelId: 'ConsentForms.listHeaderStatus',
    defaultLabelId: 'Status',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'permissionGrantedTo',
    labelId: 'ConsentForms.labelPermissionTo',
    defaultLabelId: 'Permission Granted to',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: 'ConsentForms.listHeaderActions',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
];

const ConsentDashBoard = () => {
  const [consentList, setConsentList] = useState<ConsentListData[]>([]);
  const [consentEntireList, setConsentEntireList] = useState<ConsentListData[]>(
    [],
  );
  const [consentDataCount, setConsentDataCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [order, setOrder] = useState<Order>();
  const [orderBy, setOrderBy] = useState<keyof Data>();

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [clientName, setClientName] = React.useState('Consent');
  const [dialogFlag, setDialogFlag] = useState(false);
  const [filteredConsentListsType, setFilteredConsentListsType] =
    useState<OptionType[]>();

  const clientIdentity = localStorage.getItem('ClientId');
  const [shareModal, setShareModal] = useState<boolean>(false);
  const [selectedRowItem, setSelectedRowItem] = useState<ConsentListData>();
  const [offlineMode, setOffline] = useState(false);

  const { updateConsentJSON, updateConsentVersionNo } = useContext(
    ConsentContext,
  ) as ConsentContextType;

  const { lookupsDemographics } = useContext(
    LookUpContext,
  ) as LookUpContextType;

  const ITEMS_PER_PAGE = 10;

  useLayoutEffect(() => {
    const getOfflineMode = async () => {
      const offlineFlag = await checkOffline();
      setOffline(offlineFlag);
    };
    getOfflineMode();
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleConsent = (consentTypeValue: string) => {
    if (clientIdentity) {
      setDialogFlag(false);
      // need to cross check the version
      let consentVersion = 0;
      if (consentEntireList.length > 0) {
        //need to update based on version
        const count = consentEntireList.filter(
          (consent) => consent.consentType === consentTypeValue,
        ).length;
        consentVersion = count + 1;
      } else {
        consentVersion = 1;
      }
      getConsentDate(consentTypeValue, '', consentVersion);
    }
  };

  const getConsentDate = (
    selectedConsent: string,
    selectedConsentId: string,
    selectedConsentVersion: number,
  ) => {
    toggleLoader(true);
    getConsentDetails(
      selectedConsent,
      selectedConsentVersion,
      selectedConsentId,
    )
      .then(async (response) => {
        if (response) {
          updateConsentJSON(response);
          updateConsentVersionNo(selectedConsentVersion);

          setTimeout(() => {
            navigate(CONSENT_RELEASE_INFO);
            toggleLoader(false);
          }, 0);
        }
      })
      .catch(async (error) => {
        toggleLoader(false);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('ConsentForms.concentLoadError');
          setToastrDefaultMessage('Failed to load Consent details');
        }
      });
  };

  function compareValues<T>(a: T, b: T, orderType: Order): number {
    if (orderType === 'asc') {
      return a < b ? -1 : a > b ? 1 : 0;
    } else {
      return a > b ? -1 : a < b ? 1 : 0;
    }
  }

  function sortConsents(
    consents: ConsentListData[],
    typeOrder: Order,
    versionOrder: Order,
    dateOrder: Order,
  ): ConsentListData[] {
    return consents.sort((a, b) => {
      const typeComparison = compareValues(
        a.consentType,
        b.consentType,
        typeOrder,
      );
      if (typeComparison !== 0) return typeComparison;

      const versionComparison = compareValues(
        a.version,
        b.version,
        versionOrder,
      );
      if (versionComparison !== 0) return versionComparison;

      return compareValues(
        new Date(a.consentDate).getTime(),
        new Date(b.consentDate).getTime(),
        dateOrder,
      );
    });
  }

  const getConsentListDetails = (clientId: string) => {
    if (clientId) {
      toggleLoader(true);
      getConsentList('consentDate', 'desc'.toUpperCase())
        .then((response) => {
          const sortedConsents = sortConsents(
            response.consents,
            'asc',
            'desc',
            'desc',
          );

          setConsentEntireList(sortedConsents);
          const start = page * ITEMS_PER_PAGE;
          const end = start + ITEMS_PER_PAGE;
          setConsentList(sortedConsents.slice(start, end));
          setFilteredConsentListsType(consentType);
          setConsentDataCount(response.totalConsentsCount);
          toggleLoader(false);
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('ConsentForms.concentLoadError');
            setToastrDefaultMessage('Failed to load Consent details');
          }
        });
    }
  };
  useEffect(() => {
    if (lookupsDemographics !== null) {
      const fullName = getFullNameWithMiddleName(
        lookupsDemographics.firstName ? lookupsDemographics.firstName : '',
        lookupsDemographics.middleName ? lookupsDemographics.middleName : '',
        lookupsDemographics.lastName ? lookupsDemographics.lastName : '',
      );
      setClientName(fullName);
    }

    if (clientIdentity) getConsentListDetails(clientIdentity);
  }, []);

  useEffect(() => {
    if (clientIdentity) {
      const sortedConsents = sortConsents(
        consentEntireList,
        'asc',
        'desc',
        'desc',
      );
      const start = page * ITEMS_PER_PAGE;
      const end = start + ITEMS_PER_PAGE;
      setConsentList(sortedConsents.slice(start, end));
    }
  }, [page]);

  const onViewClick = (selectedRow: ConsentListData) => {
    if (clientIdentity)
      // neeed to update
      getConsentDate(
        selectedRow.consentType,
        selectedRow.consentId,
        selectedRow.version,
      );
  };

  const onDownloadClick = (selectedRow: ConsentListData) => {
    toggleLoader(true);
    const localTimezone = dayjs.tz.guess();
    downloadConsent(
      selectedRow.consentType,
      selectedRow.consentId,
      localTimezone,
    )
      .then((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });

        // Create a Blob URL
        const blobUrl = URL.createObjectURL(blob);

        // Open the Blob URL in a new tab
        const anchor = document.createElement('a');
        anchor.href = blobUrl;
        anchor.target = '_blank';
        anchor.download = `${selectedRow.consentName}.pdf`; // Set desired file name here

        // Append anchor to document body
        document.body.appendChild(anchor);

        // Trigger anchor click
        anchor.click();

        window.open(blobUrl, '_blank');

        // Clean up after use
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(anchor);

        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('ConsentForms.downloadErrorMsg');
          setToastrDefaultMessage('Failed to download');
        }
      });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onShareClick = (selectedRow: ConsentListData) => {
    setShareModal(true);
    setSelectedRowItem(selectedRow);
  };

  return (
    <>
      <Box component="main">
        <Box component="section">
          <SnackBarComponent
            open={open}
            handleClose={handleClose}
            successOrError={toastrVariable}
            labelId={toastrId}
            defaultMessageId={toastrDefaultMessage}
          />
          <Dialog open={dialogFlag} fullWidth maxWidth="sm">
            <DialogTitle className="pb-none">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center', // Align items vertically in the center
                }}
              >
                <MediumTypography
                  labelid={'forms.titleText'}
                  sxProps={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                  defaultlabel="Forms"
                />
                <Close
                  style={{
                    marginLeft: 'auto', // Move the Close button to the right side
                    marginTop: '16px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setDialogFlag(false)}
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              {filteredConsentListsType &&
                filteredConsentListsType.map((eventProps: OptionType) => {
                  return (
                    <Box sx={{ marginY: '10px' }} key={eventProps.id}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                        onClick={() => {
                          handleConsent(eventProps.id);
                        }}
                      >
                        <MediumTypography
                          label={eventProps.label}
                          sxProps={{ cursor: 'pointer' }}
                        />
                      </Box>

                      <SingleLine
                        sxContainerProps={{ marginY: '8px' }}
                        lineWidth="100%"
                      />
                    </Box>
                  );
                })}
              {filteredConsentListsType &&
                filteredConsentListsType.length === 0 && (
                  <Box className="flex__ align__items__center">
                    <MediumTypography
                      labelid="forms.noFormsText"
                      defaultlabel="No more forms available to add."
                    />
                  </Box>
                )}
            </DialogContent>
          </Dialog>
          <Box component="div" className="rowContainer">
            <Grid container>
              <Grid item xs={6} lg={6}>
                <Box className="flex__ align__items__center">
                  <Box className="flex__ ">
                    <CustomBackArrow onClick={() => navigate(FACESHEET)} />
                  </Box>

                  <TitleText
                    className="ml-md"
                    Sxprops={{
                      ml: 1,
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '600px',
                    }}
                    label={clientName}
                  />
                </Box>
              </Grid>
              {consentList.length > 0 &&
                checkPermissionForFeature(
                  'backend.clients',
                  'editPermission',
                ) && (
                  <Grid item xs={6} lg={6} className="text-align-end">
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      type="submit"
                      labelId="ConsentForms.buttonLabel"
                      defaultLabelId="Add Consent"
                      onClick={() => setDialogFlag(true)}
                    />
                  </Grid>
                )}
            </Grid>
          </Box>

          {consentList.length > 0 && (
            <Card>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHeader
                    className="listDataTableHead"
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headerNames={headCells}
                    checkBoxRequired={true}
                  />
                  <TableBody className="tableRowcss">
                    {consentList.length !== 0 &&
                      consentList.map((consentData) => (
                        <ConsentTableRow
                          key={consentData.consentId}
                          data={consentData}
                          onView={onViewClick}
                          onDownload={onDownloadClick}
                          onShare={onShareClick}
                          offlineFlag={offlineMode}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          )}
          {consentDataCount > ITEMS_PER_PAGE && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <AppPagination
                pageNumber={page}
                paginationCount={consentDataCount}
                handleChangePage={(_event, newPage) => {
                  if (newPage - 1 !== page) {
                    setPage(newPage - 1);
                  }
                }}
              />
            </Box>
          )}
        </Box>
      </Box>

      {shareModal && (
        <ShareDownloadModal
          screenName={'consent'}
          modalVisible={shareModal}
          closeModal={() => setShareModal(false)}
          consentData={selectedRowItem}
        />
      )}

      {consentList.length === 0 && (
        <Box component="main" className="mb-md">
          <Box component="section">
            <EmptyScreen
              titleLabelId="ConsentForms.emptyMessage"
              defaultTitleText="No Consent Forms added yet"
              buttonLabelId="ConsentForms.buttonLabel"
              defaultButtonTitleText="Add Consent"
              showButton={checkPermissionForFeature(
                'backend.clients',
                'editPermission',
              )}
              onButtonClick={() => {
                setDialogFlag(true);
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ConsentDashBoard;

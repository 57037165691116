import { Box } from '@mui/system';
import MediumTypography from '../../components/formlib/MediumTypography';
import { Grid, Tooltip } from '@mui/material';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { OptionType } from '../../utils/type';
import { ConsentClientContact } from '../../services/configApi/forms/Consent/ConsentServices';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import Textinput from '../../components/formlib/Textinput';
import { nameValidation } from '../../utils/formValidationUtil';

interface ContactDetails {
  contacts: ConsentClientContact[] | [];
  titleLabel: string;
  defaultTitleLabel: string;
  searchContactLabel: string;
  defaultSearchContactLabel: string;
  emailLabelId: string;
  emailDefaultLabelId: string;
  addressLabelId: string;
  addressDefaultLabelId: string;
  phoneLabelId: string;
  phoneDefaultLabelId: string;
  contactOtherName: string;
  dropDownValidation: boolean;
  dropDownOtherContactValidationState: boolean;
  contactData: OptionType[];
  onSelect: (value: OptionType[] | null) => void;
  onChangeText: (value: ChangeEvent<HTMLInputElement>) => void;
  handleContactOtherName: (value: string) => void;
}

const ConsentSspAgencyDetails: FC<ContactDetails> = (props) => {
  const [selectAgency, setSelectAgency] = useState<string>('');
  const [selectedContact, setSelectedContact] = useState<
    ConsentClientContact[]
  >([]);

  useEffect(() => {
    const handleSelected = async () => {
      if (props.contacts.length > 0) {
        const selectedAgency = props.contacts.find(
          (contact) => contact.id.toString() === selectAgency,
        );
        if (selectedAgency) {
          setSelectedContact([selectedAgency]);
        } else {
          const firstContact = props.contacts[0];
          setSelectAgency(firstContact.id.toString());
          setSelectedContact([firstContact]);
        }
      }
    };

    handleSelected();
  }, [props.contacts]); // Trigger when props.contacts changes

  const handleDropdownChange = (value: string) => {
    if (value) {
      const selectedList: OptionType[] = [];
      props.contactData.forEach((v) =>
        selectedList.push({ id: v.id, label: v.label }),
      );
      setSelectAgency(value);

      const selectedContactList = props.contactData.filter((v) => {
        return v.id.toString() === value.toString();
      });

      props.onSelect(selectedContactList);
    } else {
      const selectedList: OptionType[] = [];
      props.contactData.forEach((v) =>
        selectedList.push({ id: v.id, label: v.label }),
      );
      setSelectedContact([]); // Clear the selected contact when no value is selected
      props.onSelect(selectedList);
    }
  };

  return (
    <Box className="width__100 pb-sm">
      <MediumTypography
        labelid={props.titleLabel}
        defaultlabel={props.defaultTitleLabel}
        fontSize={'18px'}
        fontweight={600}
        className="pt-sm pb-md"
      />
      <Grid container>
        <Grid item xs={4} className="multisearchHgt">
          <DropdownComponent
            names={props.contactData}
            labelid={props.searchContactLabel}
            value={selectAgency}
            defaultlabelid={props.defaultSearchContactLabel}
            handleChange={handleDropdownChange}
            Required
          />
          {props.dropDownValidation && (
            <MediumTypography
              labelid={'Intake.EICSField.required'}
              textColor="red"
              marginTop="8px"
            />
          )}
        </Grid>
      </Grid>
      {selectedContact.length > 0 &&
        selectedContact.map((details) =>
          details.type !== 'Other' ? (
            <Box className="pt-md" key={details.id}>
              <Box className="width200px">
                <Tooltip
                  title={
                    details.name && details.name.length > 5 ? details.name : ''
                  }
                  followCursor
                  arrow
                >
                  <Box>
                    <MediumTypography
                      fontSize="16px"
                      textColor="#2A4241"
                      label={details.name || '-'}
                      sxProps={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>
              <Box className="flex__ pt-sm">
                <Box className="flex__ pr-md">
                  <MediumTypography
                    labelid={props.addressLabelId}
                    defaultlabel={props.addressDefaultLabelId}
                    fontSize="16px"
                    textColor="#97A6A5"
                  />
                  <Box className="width300px">
                    <Tooltip
                      title={
                        details.address && details.address.length > 10
                          ? details.address.replace(/,/g, ', ')
                          : ''
                      }
                      followCursor
                      arrow
                    >
                      <Box>
                        <MediumTypography
                          fontSize="16px"
                          className="pl-xs"
                          textColor="#2A4241"
                          label={
                            (details.address &&
                              details.address.replace(/,/g, ', ')) ||
                            '-'
                          }
                          sxProps={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
                <Box display={'flex'} paddingRight={'16px'}>
                  <MediumTypography
                    labelid={props.emailLabelId}
                    defaultlabel={props.emailDefaultLabelId}
                    fontSize="16px"
                    textColor="#97A6A5"
                  />
                  <Box className="width200px">
                    <Tooltip
                      title={
                        details.email && details.email.length > 10
                          ? details.email
                          : ''
                      }
                      followCursor
                      arrow
                    >
                      <Box>
                        <MediumTypography
                          fontSize="16px"
                          className="pl-xs"
                          textColor="#2A4241"
                          label={details.email || '-'}
                          sxProps={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
                <Box className="flex__ pr-md">
                  <MediumTypography
                    labelid={props.phoneLabelId}
                    defaultlabel={props.phoneDefaultLabelId}
                    fontSize="16px"
                    textColor="#97A6A5"
                  />
                  <Box className="width200px">
                    <Tooltip
                      title={
                        details.phone && details.phone.length > 10
                          ? details.phone
                          : ''
                      }
                      followCursor
                      arrow
                    >
                      <Box>
                        <MediumTypography
                          fontSize="16px"
                          className="pl-xs"
                          textColor="#2A4241"
                          label={details.phone || '-'}
                          sxProps={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            details.type === 'Other' && (
              <Grid container className="pt-md">
                <Grid item xs={4} className="multisearchHgt">
                  <Textinput
                    labelid="ConsentForms.otherContactLabel"
                    defaultlabelid="Other Contact Name"
                    Required={true}
                    inputProps={{
                      maxLength: 50,
                    }}
                    handlechange={(value: string) => {
                      const inputValue = value;
                      const validatedValue = nameValidation(inputValue);
                      if (validatedValue !== undefined) {
                        props.handleContactOtherName(validatedValue);
                      }
                    }}
                    Value={props.contactOtherName}
                  />
                  {props.dropDownOtherContactValidationState && (
                    <MediumTypography
                      labelid={'Clientpage.immuneerror'}
                      textColor="red"
                      marginTop="8px"
                    />
                  )}
                </Grid>
              </Grid>
            )
          ),
        )}
    </Box>
  );
};

export default ConsentSspAgencyDetails;

import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box } from '@mui/system';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as DownArrow } from '../../assets/images/downarrowcolored.svg';
import { ReactComponent as UpArrow } from '../../assets/images/uparrowcolored.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/deleteAlertIcon.svg';
import { ReactComponent as AddIcon } from '../../assets/images/CircleAdd.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/infoIcon.svg';
import InformationModal from './InformationModal';
import HealthEligibilitySummaryData from './IFSP/HealthEligibilitySummary.json';
import { Grid, Paper, Table, TableBody, TableContainer } from '@mui/material';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import SimpleTextAndTextArea from '../../components/forms/SimpleTextAndTextArea';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import EvaluationTableRow from './EvaluationTableRow';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';
import IfspContext from './ifspContextApi/IfspContex';
import ModalPopup from '../../components/formlib/ModalPopup';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { EmployeeListType } from '../../services/ifspClient/IfspApi';
import AutocompleteSearch from '../../components/formlib/AutocompleteSearch';
import { OptionType } from '../../utils/type';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';

interface HealthEligibilitySummaryProps {
  close: boolean;
  handelClose: Dispatch<SetStateAction<boolean>>;
  info: boolean;
  employeeList: EmployeeListType[];
  closeInfo: (info: boolean) => void;
  openInfo: (info: boolean) => void;
  isIfspScreenLocked: boolean;
}

type EvalutaionDataType = {
  evaluationId: number;
  evaluationName: string;
  evaluationDiscipline: string;
};

interface TableDataType {
  evaluationName: string;
  evaluationDiscipline: string;
  action: string;
}

type MainHESDataType = {
  dateOfEvaluation: string | null | Dayjs;
  teamMember: {
    name: string;
    memberName: string;
    discipline: string;
  }[];
  evaluationResults: string;
  healthHistory: string;
  currentHealthStatus: string;
  additionalInfo: string;
  isChecked: boolean;
  sessionReviewedDate: string | null | Dayjs;
};

const headCells: HeadCell<TableDataType>[] = [
  {
    id: 'evaluationName',
    labelId: 'Evaluation Name',
    defaultLabelId: 'Evaluation Team Member Name',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'evaluationDiscipline',
    labelId: 'Evaluation Discipline',
    defaultLabelId: 'Evaluation Team Member Discipline',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'action',
    labelId: 'Action',
    defaultLabelId: 'Action',
    numeric: false,
    requiredSorting: false,
  },
];

const validationSchema = yup.object().shape({
  evaluationName: yup.string().required('IFSP.EvaluationName'),
  evaluationDecipline: yup.string().required('IFSP.EvaluationDiscipline'),
});

const HealthEligibilitySummary: FC<HealthEligibilitySummaryProps> = ({
  close,
  handelClose,
  employeeList,
  info,
  openInfo,
  closeInfo,
  isIfspScreenLocked,
}) => {
  const [hesServiceQuestion, setHESServiceQuestion] = useState<
    { commentId: number; comments: string }[]
  >(
    HealthEligibilitySummaryData.Questions.map((_, index) => ({
      commentId: index,
      comments: '',
    })),
  );
  const [evaluvationData, setEvaluvationData] = useState<EvalutaionDataType[]>(
    [],
  );

  const { ifsp, updateIfspContext } = useContext(IfspContext);
  const [newEvaluvatedName, setNewEvaluvatedName] = useState('');
  const [newEvaluvatedDiscipline, setNewEvaluvatedDiscipline] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [editEvaluationId, setEditEvaluationId] = useState<number>(0);
  const [mainHesData, setMainHesData] = useState<MainHESDataType[]>(
    ifsp.ifspTeam.forms.healthSummary,
  );
  const [disciplineList, setDisciplineList] = useState<OptionType[]>([
    {
      id: '',
      label: '',
    },
  ]);

  const [deleteIndex, setDeleteIndex] = useState<number>(0);
  const [deleteWaring, setDeleteWarning] = useState<boolean>(false);
  const [deleteNewSection, setDeleteNewSection] = useState<boolean>(false);
  const [openAccordianIndex, setOpenAccordianIndex] = useState<number>(0);
  const [openAccordian, setOpenAccordian] = useState<boolean>(false);

  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);

  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);
  const parentRef = useRef<HTMLDivElement>(null);
  const { lookups } = useContext(LookUpContext) as LookUpContextType;

  const formikRef = useRef<
    FormikProps<{
      evaluationName: string;
      evaluationDecipline: string;
    }>
  >(null);

  const addEvaluationdata = (values: {
    evaluationName: string;
    evaluationDecipline: string;
  }) => {
    if (isEdit) {
      const update = mainHesData[openAccordianIndex].teamMember.map(
        (member, index) =>
          index === editEvaluationId
            ? {
                ...member,
                name: values.evaluationName,
                discipline:
                  employeeList.find(
                    (emp) => emp.id === values.evaluationDecipline,
                  )?.disciplineType ?? '',
              }
            : member,
      );
      setMainHesData((prev) => {
        return prev.map((data, index) =>
          index === openAccordianIndex
            ? {
                ...data,
                teamMember: update,
              }
            : data,
        );
      });
      const updatedEvaluationData = evaluvationData.map((evaluation) =>
        evaluation.evaluationId === editEvaluationId
          ? {
              ...evaluation,
              evaluationName: values.evaluationName,
              evaluationDiscipline:
                employeeList.find(
                  (emp) => emp.id === values.evaluationDecipline,
                )?.disciplineType ?? '',
            }
          : evaluation,
      );
      setEvaluvationData(updatedEvaluationData);
      setIsEdit(false);
    } else {
      setMainHesData((prev) => {
        return prev.map((data, index) =>
          index === openAccordianIndex
            ? {
                ...data,
                teamMember: [
                  ...data.teamMember,
                  {
                    name: values.evaluationName,
                    memberName:
                      employeeList.find(
                        (emp) => emp.id === values.evaluationName,
                      )?.name ?? '',
                    discipline:
                      employeeList.find(
                        (emp) => emp.id === values.evaluationDecipline,
                      )?.disciplineType ?? '',
                  },
                ],
              }
            : data,
        );
      });
      setEvaluvationData([
        ...evaluvationData,
        {
          evaluationId: evaluvationData.length,
          evaluationName: values.evaluationName,
          evaluationDiscipline:
            employeeList.find((emp) => emp.id === values.evaluationDecipline)
              ?.disciplineType ?? '',
        },
      ]);
    }
    setNewEvaluvatedName('');
    setNewEvaluvatedDiscipline('');
  };

  const editEvaluationData = (data: EvalutaionDataType) => {
    const discipline = employeeList
      .filter((employee) => employee.id === data.evaluationName)
      .map((employee) => ({
        id: employee.id ?? '',
        label:
          lookups?.discipline.find(
            (disc) => disc.code === employee.disciplineType,
          )?.description ?? '',
      }));
    setDisciplineList(discipline);
    setEditEvaluationId(data.evaluationId);
    formikRef.current?.setValues({
      evaluationName: data.evaluationName,
      evaluationDecipline: data.evaluationName,
    });
    setNewEvaluvatedName(data.evaluationName);
    setNewEvaluvatedDiscipline(data.evaluationDiscipline);
    setIsEdit(true);
  };
  const deleteEvaluationData = (id: number) => {
    setDeleteIndex(id);
    setDeleteWarning(true);
  };

  useEffect(() => {
    setMainHesData(ifsp.ifspTeam.forms.healthSummary);
    setEvaluvationData(() => {
      return ifsp.ifspTeam.forms.healthSummary[
        openAccordianIndex
      ].teamMember.map((member, index) => {
        return {
          evaluationId: index,
          evaluationName: member.name,
          evaluationDiscipline: member.discipline,
        };
      });
    });
    setHESServiceQuestion((prev) => {
      const tempData = [...prev];
      tempData[0].comments =
        ifsp.ifspTeam.forms.healthSummary[openAccordianIndex].evaluationResults;
      tempData[1].comments =
        ifsp.ifspTeam.forms.healthSummary[openAccordianIndex].healthHistory;
      tempData[2].comments =
        ifsp.ifspTeam.forms.healthSummary[
          openAccordianIndex
        ].currentHealthStatus;
      tempData[3].comments =
        ifsp.ifspTeam.forms.healthSummary[openAccordianIndex].additionalInfo;
      return tempData;
    });
  }, [openAccordianIndex]);

  useEffect(() => {
    updateIfspContext({
      ...ifsp,
      ifspTeam: {
        ...ifsp.ifspTeam,
        forms: {
          ...ifsp.ifspTeam.forms,
          healthSummary: mainHesData,
        },
      },
    });
  }, [mainHesData]);
  useEffect(() => {
    if (close) setOpenAccordian(false);
  }, [close]);
  const createNewSection = () => {
    updateIfspContext({
      ...ifsp,
      ifspTeam: {
        ...ifsp.ifspTeam,
        forms: {
          ...ifsp.ifspTeam.forms,
          healthSummary: [
            ...ifsp.ifspTeam.forms.healthSummary,
            {
              teamMember: [],
              evaluationResults: '',
              healthHistory: '',
              currentHealthStatus: '',
              additionalInfo: '',
              isChecked: true,
              sessionReviewedDate: '',
              dateOfEvaluation: '',
            },
          ],
        },
      },
    });
    setMainHesData([
      ...ifsp.ifspTeam.forms.healthSummary,
      {
        teamMember: [],
        evaluationResults: '',
        healthHistory: '',
        currentHealthStatus: '',
        additionalInfo: '',
        isChecked: true,
        sessionReviewedDate: '',
        dateOfEvaluation: '',
      },
    ]);
  };
  const deleteSection = () => {
    updateIfspContext({
      ...ifsp,
      ifspTeam: {
        ...ifsp.ifspTeam,
        forms: {
          ...ifsp.ifspTeam.forms,
          healthSummary: ifsp.ifspTeam.forms.healthSummary.slice(0, -1),
        },
      },
    });
    setMainHesData(ifsp.ifspTeam.forms.healthSummary.slice(0, -1));
    setDeleteNewSection(false);
  };
  return (
    <Formik
      initialValues={{
        evaluationName: newEvaluvatedName,
        evaluationDecipline: newEvaluvatedDiscipline,
      }}
      innerRef={formikRef}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        resetForm();
        setDisciplineList([]);
        addEvaluationdata(values);
      }}
    >
      {({
        values,
        setFieldValue,
        setValues,
        setErrors,
        errors,
        touched,
        handleSubmit,
      }) => (
        <Box component="main">
          {info && (
            <InformationModal
              open={info}
              titleLabel="IFSP.Information"
              titleDefaultLabel="Information"
              buttonLabel="IFSP.InfoOkButton"
              buttonDefaultLabel="Ok"
              onClose={() => {
                closeInfo(info);
              }}
              info={HealthEligibilitySummaryData.info}
            />
          )}

          <ModalPopup
            open={deleteWaring}
            description="deleteConfirmationMessage"
            onOk={() => {
              const newEvaluationData = evaluvationData.filter(
                (evaluation) => evaluation.evaluationId !== deleteIndex,
              );
              setMainHesData((prev) => {
                const tempData = [...prev];
                tempData[openAccordianIndex].teamMember = newEvaluationData.map(
                  (evaluation) => ({
                    name: evaluation.evaluationName,
                    memberName: evaluation.evaluationName,
                    discipline: evaluation.evaluationDiscipline,
                  }),
                );
                return tempData;
              });
              setEvaluvationData(newEvaluationData);
              setDeleteWarning(false);
            }}
            onCancel={() => {
              setDeleteWarning(false);
            }}
            labelId1="Clientpage.cancelbtn"
            negativeActionLabel="cancelText"
            labelId2="Clientpage.Okbtn"
            positiveActionLabel="Ok"
          />

          <ModalPopup
            open={deleteNewSection}
            description="deleteConfirmationMessage"
            onOk={() => {
              deleteSection();
            }}
            onCancel={() => {
              setDeleteNewSection(false);
            }}
            labelId1="Clientpage.cancelbtn"
            negativeActionLabel="cancelText"
            labelId2="Clientpage.Okbtn"
            positiveActionLabel="Ok"
          />
          {ifsp.ifspTeam.forms.healthSummary.map((ifspData, mainIndex) => (
            <Box
              component="section"
              className="p-lg gray3 borderRadius4 mb-lg"
              sx={{
                border: '1px solid #00C6B8',
              }}
            >
              <Box component="div" className="flex__justify__space-between">
                {openAccordian && mainIndex === openAccordianIndex ? (
                  <Box component="div" className="flex__justify__space-between">
                    <MediumTypography
                      labelid="IFSP.session2"
                      defaultlabel="Health & Eligibility Summary"
                      fontSize="18px"
                      fontweight={600}
                      textColor="#2A4241"
                      className="pr-md"
                    />
                    <InfoIcon
                      style={{ alignSelf: 'center' }}
                      onClick={() => {
                        openInfo(info);
                      }}
                    />
                    {ifsp.ifspTeam.forms.healthSummary[mainIndex]
                      .sessionReviewedDate ? (
                      <Box
                        sx={{
                          display: 'flex',
                          padding: '4px 8px 4px 8px',
                          borderRadius: '4px',
                          marginLeft: '24px',
                          backgroundColor: '#97A6A5',
                          alignItems: 'center',
                        }}
                      >
                        <MediumTypography
                          labelid="IFSP.UpdatedOn"
                          defaultlabel="Updated On: "
                          fontSize="14px"
                          fontweight={400}
                          textColor="#fff"
                        />
                        <MediumTypography
                          fontSize="14px"
                          fontweight={400}
                          textColor="#fff"
                          label={ifspData.sessionReviewedDate as string}
                        />
                      </Box>
                    ) : null}
                  </Box>
                ) : (
                  <Box className="flex__justify__space-between">
                    <MediumTypography
                      labelid="IFSP.session2"
                      defaultlabel="Health & Eligibility Summary"
                      fontSize="18px"
                      fontweight={600}
                      textColor="#2A4241"
                    />
                    {ifsp.ifspTeam.forms.healthSummary[mainIndex]
                      .sessionReviewedDate ? (
                      <Box
                        sx={{
                          display: 'flex',
                          padding: '4px 8px 4px 8px',
                          borderRadius: '4px',
                          marginLeft: '24px',
                          backgroundColor: '#97A6A5',
                          alignItems: 'center',
                        }}
                      >
                        <MediumTypography
                          labelid="IFSP.UpdatedOn"
                          defaultlabel="Updated On: "
                          fontSize="14px"
                          fontweight={400}
                          textColor="#fff"
                        />
                        <MediumTypography
                          fontSize="14px"
                          fontweight={400}
                          textColor="#fff"
                          label={ifspData.sessionReviewedDate as string}
                        />
                      </Box>
                    ) : null}
                  </Box>
                )}
                {openAccordian && mainIndex === openAccordianIndex ? (
                  <Box className="flex__ align__items__center">
                    {!isIfspScreenLocked &&
                    !isScreenLocked &&
                    ifsp.ifspTeam.forms.healthSummary[
                      ifsp.ifspTeam.forms.healthSummary.length - 1
                    ].sessionReviewedDate &&
                    ifsp.ifspTeam.forms.healthSummary.length - 1 ===
                      mainIndex ? (
                      <Box
                        className="cursorPointer"
                        sx={{
                          padding: '4px 8px 4px 8px',
                          borderRadius: '4px',
                          marginRight: '24px',
                          border: '1px solid #008C82',
                          backgroundColor: '#fff',
                        }}
                        onClick={() => {
                          createNewSection();
                        }}
                      >
                        <MediumTypography
                          labelid="IFSP.AddNewSection"
                          defaultlabel="+ Add New Section"
                          fontSize="12px"
                          fontweight={600}
                        />
                      </Box>
                    ) : !isIfspScreenLocked &&
                      !isScreenLocked &&
                      !ifsp.ifspTeam.forms.healthSummary[
                        ifsp.ifspTeam.forms.healthSummary.length - 1
                      ].sessionReviewedDate &&
                      ifsp.ifspTeam.forms.healthSummary.length - 1 ===
                        mainIndex &&
                      mainIndex > 0 ? (
                      <Box
                        component={'div'}
                        className="cursorPointer flex__ align__items__center pr-md"
                        onClick={() => {
                          setDeleteNewSection(true);
                        }}
                        sx={{
                          padding: '4px 8px 4px 8px',
                          borderRadius: '4px',
                          marginRight: '24px',
                          border: '1px solid #EB4C60',
                          backgroundColor: '#fff',
                        }}
                      >
                        <DeleteIcon className="pr-xs" />
                        <MediumTypography
                          labelid="IFSP.DeleteNewSection"
                          defaultlabel="Delete"
                          fontSize="12px"
                          fontweight={600}
                        />
                      </Box>
                    ) : null}
                    <UpArrow
                      className="cursorPointer"
                      onClick={() => {
                        setOpenAccordianIndex(openAccordianIndex);
                        setOpenAccordian(false);
                      }}
                    />
                  </Box>
                ) : (
                  <Box className="flex__ align__items__center">
                    {!isIfspScreenLocked &&
                    !isScreenLocked &&
                    ifsp.ifspTeam.forms.healthSummary[
                      ifsp.ifspTeam.forms.healthSummary.length - 1
                    ].sessionReviewedDate &&
                    ifsp.ifspTeam.forms.healthSummary.length - 1 ===
                      mainIndex ? (
                      <Box
                        className="cursorPointer"
                        sx={{
                          padding: '4px 8px 4px 8px',
                          borderRadius: '4px',
                          marginRight: '24px',
                          border: '1px solid #008C82',
                          backgroundColor: '#fff',
                        }}
                        onClick={() => {
                          createNewSection();
                        }}
                      >
                        <MediumTypography
                          labelid="IFSP.AddNewSection"
                          defaultlabel="+ Add New Section"
                          fontSize="12px"
                          fontweight={600}
                        />
                      </Box>
                    ) : !isIfspScreenLocked &&
                      !isScreenLocked &&
                      !ifsp.ifspTeam.forms.healthSummary[
                        ifsp.ifspTeam.forms.healthSummary.length - 1
                      ].sessionReviewedDate &&
                      ifsp.ifspTeam.forms.healthSummary.length - 1 ===
                        mainIndex &&
                      mainIndex > 0 ? (
                      <Box
                        component={'div'}
                        className="cursorPointer flex__ align__items__center pr-md"
                        onClick={() => {
                          setDeleteNewSection(true);
                        }}
                        sx={{
                          padding: '4px 8px 4px 8px',
                          borderRadius: '4px',
                          marginRight: '24px',
                          border: '1px solid #EB4C60',
                          backgroundColor: '#fff',
                        }}
                      >
                        <DeleteIcon className="pr-xs" />
                        <MediumTypography
                          labelid="IFSP.DeleteNewSection"
                          defaultlabel="Delete"
                          fontSize="12px"
                          fontweight={600}
                        />
                      </Box>
                    ) : null}
                    <DownArrow
                      className="cursorPointer"
                      onClick={() => {
                        setOpenAccordianIndex(mainIndex);
                        setOpenAccordian(true);
                        handelClose(false);
                      }}
                    />
                  </Box>
                )}
              </Box>
              {openAccordian && mainIndex === openAccordianIndex && (
                <Box component="div" className="mt-lg">
                  <div
                    ref={parentRef}
                    style={{
                      pointerEvents: isScreenLocked ? 'none' : 'auto',
                      opacity: isScreenLocked ? '0.5' : '1 ',
                    }}
                    onKeyDownCapture={(e) => {
                      if (isScreenLocked) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                    onFocus={() => {
                      if (isScreenLocked) {
                        if (parentRef.current) parentRef.current.focus();
                      }
                    }}
                  >
                    <Grid container xs={12}>
                      <Grid item xs={5}>
                        <DatePickerComponent
                          className="bg__white"
                          labelid="IFSP.eligibilityEvaluationDate"
                          disableFuture={false}
                          defaultlabelid="Date of Eligibility Evaluation"
                          value={
                            mainHesData[openAccordianIndex].dateOfEvaluation ===
                              '' || null
                              ? null
                              : (dayjs(
                                  mainHesData[openAccordianIndex]
                                    .dateOfEvaluation,
                                ) as Dayjs)
                          }
                          handlechange={(dateValue: Dayjs | null) => {
                            if (dateValue !== null) {
                              const formattedDate =
                                dayjs(dateValue).format('MM/DD/YYYY');
                              setMainHesData((prev) =>
                                prev.map((item, index) => {
                                  if (index === openAccordianIndex) {
                                    return {
                                      ...item,
                                      dateOfEvaluation: formattedDate,
                                    };
                                  }
                                  return item;
                                }),
                              );
                            } else {
                              setMainHesData((prev) =>
                                prev.map((item, index) => {
                                  if (index === openAccordianIndex) {
                                    return {
                                      ...item,
                                      dateOfEvaluation: null,
                                    };
                                  }
                                  return item;
                                }),
                              );
                            }
                          }}
                          disabledDate={
                            ifspData.sessionReviewedDate ? true : false
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container className="mt-lg">
                      <Grid item xs={4.5} className="pr-lg">
                        <AutocompleteSearch
                          className="consentAutoSearch bg__white"
                          labelId="IFSP.evaluationTeamMemberName"
                          defaultId="Evaluation Team Member Name"
                          Required
                          data={employeeList
                            .filter(
                              (employee) =>
                                !ifsp.ifspTeam.forms.healthSummary[
                                  openAccordianIndex
                                ].teamMember.some(
                                  (teamMember) =>
                                    teamMember.name === employee.id,
                                ),
                            )
                            .map((employee) => ({
                              id: employee.id ?? '',
                              label: employee.name ?? '',
                            }))}
                          selectedValue={{
                            id:
                              employeeList.find(
                                (employee) =>
                                  employee.id === values.evaluationName,
                              )?.id ?? '',
                            label:
                              employeeList.find(
                                (employee) =>
                                  employee.id === values.evaluationName,
                              )?.name ?? '',
                          }}
                          onSelectItem={(data) => {
                            if (data) {
                              setFieldValue('evaluationName', data.id);
                              const discipline = employeeList
                                .filter((employee) => employee.id === data.id)
                                .map((employee) => ({
                                  id: employee.id ?? '',
                                  label:
                                    lookups?.discipline.find(
                                      (dis) =>
                                        dis.code === employee.disciplineType,
                                    )?.description ?? '',
                                }));
                              if (discipline.length > 0) {
                                setValues({
                                  evaluationName: data.id,
                                  evaluationDecipline: '',
                                });
                                setErrors({
                                  evaluationName: errors.evaluationName,
                                  evaluationDecipline:
                                    'IFSP.EvaluationDecipline',
                                });
                                setDisciplineList(discipline);
                              } else {
                                setDisciplineList([]);
                                setValues({
                                  evaluationName: '',
                                  evaluationDecipline: '',
                                });
                                setErrors({
                                  evaluationName: 'IFSP.EvaluationName',
                                  evaluationDecipline:
                                    'IFSP.EvaluationDecipline',
                                });
                              }
                            } else {
                              setDisciplineList([]);
                              setValues({
                                evaluationName: '',
                                evaluationDecipline: '',
                              });
                              setErrors({
                                evaluationName: 'IFSP.EvaluationName',
                                evaluationDecipline: 'IFSP.EvaluationDecipline',
                              });
                            }
                          }}
                          disableLabel={false}
                          disabled={ifspData.sessionReviewedDate ? true : false}
                        />
                        {errors.evaluationName && touched.evaluationName && (
                          <MediumTypography
                            className="errorText-md"
                            labelid={errors.evaluationName}
                            defaultlabel="Evaluation name is required"
                          />
                        )}
                      </Grid>
                      <Grid item xs={4.5} className="align__items__center">
                        <AutocompleteSearch
                          className="consentAutoSearch bg__white"
                          labelId="IFSP.evaluationTeamMemberDiscipline"
                          defaultId="Evaluation Team Member Discipline"
                          Required
                          data={disciplineList}
                          selectedValue={{
                            id:
                              disciplineList.find(
                                (discipline) =>
                                  discipline.id === values.evaluationDecipline,
                              )?.id ?? '',
                            label:
                              disciplineList.find(
                                (discipline) =>
                                  discipline.id === values.evaluationDecipline,
                              )?.label ?? '',
                          }}
                          onSelectItem={(data) => {
                            if (data) {
                              setFieldValue('evaluationDecipline', data.id);
                            } else {
                              setFieldValue('evaluationDecipline', '');
                            }
                          }}
                          disableLabel={false}
                          disabled={ifspData.sessionReviewedDate ? true : false}
                        />
                        {errors.evaluationDecipline &&
                          touched.evaluationDecipline && (
                            <MediumTypography
                              className="errorText-md"
                              labelid={errors.evaluationDecipline}
                              defaultlabel="Evaluation discipline is required"
                            />
                          )}
                      </Grid>
                      {!ifspData.sessionReviewedDate && (
                        <Grid item xs={2}>
                          <Box
                            component="div"
                            className="cursorPointer flex__ ml-lg mt-xs"
                            onClick={() => handleSubmit()}
                          >
                            <AddIcon className="mr-sm" />
                            <MediumTypography
                              labelid="IFSP.Add"
                              defaultlabel="Add"
                              fontweight={400}
                              textColor="#2A4241"
                            />
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                    <Box component="div" className="mt-md">
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHeader
                            className="listDataTableHead"
                            headerNames={headCells}
                            checkBoxRequired={false}
                          />
                          <TableBody className="tableRowcss">
                            {evaluvationData.map(
                              (listData: EvalutaionDataType) => (
                                <EvaluationTableRow
                                  key={listData.evaluationId}
                                  employeeList={employeeList}
                                  data={listData}
                                  onEdit={(data) => {
                                    if (!ifspData.sessionReviewedDate) {
                                      editEvaluationData(data);
                                    }
                                  }}
                                  onDelete={(data) => {
                                    if (!ifspData.sessionReviewedDate) {
                                      deleteEvaluationData(data);
                                    }
                                  }}
                                />
                              ),
                            )}
                          </TableBody>
                        </Table>
                        {evaluvationData.length === 0 && (
                          <Box component="div" className=" width__100">
                            <MediumTypography
                              textstyle={'center'}
                              labelid="IFSP.emptyMessage"
                              defaultlabel="No data added yet"
                              className="p-sm"
                            />
                          </Box>
                        )}
                      </TableContainer>
                    </Box>
                    <Box component="div" className="mt-sm">
                      <SimpleTextAndTextArea
                        placeholder="Enter comments here"
                        questions={HealthEligibilitySummaryData.Questions}
                        customCommentStyles={{
                          height: '80px',
                          minHeight: '80px',
                          minWidth: '840px',
                          width: '840px',
                        }}
                        maxLength={5000}
                        Value={hesServiceQuestion}
                        handlechange={(value: {
                          commentId: number;
                          comments: string;
                        }) => {
                          const updatedComments = [...hesServiceQuestion];
                          updatedComments[value.commentId].comments =
                            value.comments;
                          setHESServiceQuestion((prev) =>
                            prev.map((item, index) => {
                              if (index === openAccordianIndex) {
                                return {
                                  ...item,
                                  evaluationResults:
                                    updatedComments[0].comments,
                                  healthHistory: updatedComments[1].comments,
                                  currentHealthStatus:
                                    updatedComments[2].comments,
                                  additionalInfo: updatedComments[3].comments,
                                };
                              }
                              return item;
                            }),
                          );

                          const updatedData = {
                            ...mainHesData,
                            evaluationResults: updatedComments[0].comments,
                            healthHistory: updatedComments[1].comments,
                            currentHealthStatus: updatedComments[2].comments,
                            additionalInfo: updatedComments[3].comments,
                          };
                          setMainHesData((prev) =>
                            prev.map((item, index) => {
                              if (index === openAccordianIndex) {
                                return {
                                  ...item,
                                  evaluationResults:
                                    updatedData.evaluationResults,
                                  healthHistory: updatedData.healthHistory,
                                  currentHealthStatus:
                                    updatedData.currentHealthStatus,
                                  additionalInfo: updatedData.additionalInfo,
                                };
                              }
                              return item;
                            }),
                          );
                        }}
                        disabled={ifspData.sessionReviewedDate ? true : false}
                      />
                    </Box>
                  </div>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      )}
    </Formik>
  );
};

export default HealthEligibilitySummary;

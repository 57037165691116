import {
  Drawer,
  Button,
  Grid,
  Card,
  Box,
  Divider,
  IconButton,
  AlertColor,
} from '@mui/material';
import { styled } from '@mui/system';
import React, { FC, useState, useEffect, useCallback, useContext } from 'react';
import * as yup from 'yup';
import SelectComponent from '../../components/formlib/SelectComponent';
import { Formik, FormikErrors, FormikProps } from 'formik';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import MediumTypography from '../../components/formlib/MediumTypography';
import Textinput from '../../components/formlib/Textinput';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import SmallTypography from '../../components/formlib/SmallTypography';
import MultiSelectAutocompleteSearch from '../../components/formlib/MultiSelectAutocompleteSearch';
import {
  STORAGE_USER_ID_KEY,
  STORAGE_USERNAME_KEY,
} from '../../services/Constant';
import { ReactComponent as Closegray } from '../../assets/images/x.svg';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
import { ReactComponent as Users } from '../../assets/images/users.svg';
import { ReactComponent as User } from '../../assets/images/User.svg';
import { ReactComponent as MapPin } from '../../assets/images/map_pin.svg';
import { ReactComponent as AlignLeft } from '../../assets/images/align_left.svg';
import { ReactComponent as Menu } from '../../assets/images/menu.svg';
import { ReactComponent as Clock } from '../../assets/images/clock.svg';
import { useIntl } from 'react-intl';
import { ReactComponent as Calender } from '../../assets/images/Calendar.svg';
import {
  addAppointment,
  checkAvailability,
} from '../../services/schedulerEventsApi';

dayjs.extend(utc);
dayjs.extend(timezone);
import {
  // AddSchedulerEventType,
  // ScheduleOccurance,
  Client,
  // AppointmentAvailabilityCheckRequest,
  SchedulerFormikType,
  SchedulerAttendee,
  ScheduleFormikOccurance,
} from '../../utils/type';
import { DateSelectArg } from '@fullcalendar/core';
import {
  Employee,
  getAllEmployeeList,
} from '../../services/configApi/employees/employeeServices';
import UnavailabilityCheckModal from './UnavailabilityCheckModal';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import { debounce } from 'lodash';
import ModalPopup from '../../components/formlib/ModalPopup';
import _ from 'lodash';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import AppTimePicker from '../../components/formlib/AppTimePicker';
import {
  appointmentTypes,
  appointmentTypesForMultiClients,
} from './schedulerUtils';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import { SchedulerFrequency } from '../Dropdown';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
interface ModelPropType {
  showModal: boolean;
  onModalClose: (
    openFromAdd: boolean,
    toastrVariableFromAdd?: AlertColor,
    toastrMessageFromAdd?: string,
    toastrIdFromAdd?: string,
    toastrDefaultMessageFromAdd?: string,
  ) => void;
  dateSelected?: DateSelectArg;
  clients: Client[];
}

export type DateString = Dayjs | null | string;

export const SubmitButton = styled(Button)`
  background: #00938e;
  font-family: 'Lato-Regular', sans-serif;
  font-style: normal;
  color: #ffffff;
  &:hover {
    background: #00938e;
    color: #ffffff;
  }
`;

export const CancelButton = styled(Button)`
  background: #ffffff;
  font-family: 'Lato-Regular', sans-serif;
  font-style: normal;
  border: 1px solid #97a6a5;
  color: #2a4241;
  &:hover {
    background: #ffffff;
    color: #2a4241;
  }
`;

const drawerWidth = 600;

const validationSchema = yup.object().shape({
  clients: yup.array().of(yup.string()),
  location: yup.string().when('clients', {
    is: (clients: MultiSelectOptioinType[]) => clients && clients.length > 0,
    then: yup.string().required('locationRequiredMessage'),
    otherwise: yup.string(),
  }),
  appointmentType: yup.string().required('apptTypeRequiredMessage'),
  frequencyEndDate: yup.string().when('recurringEvent', {
    is: true,
    then: yup
      .string()
      .required('AddNewSchedulerEvent.fieldEmptyMessage')
      .test(
        'till-date-not-greater',
        'schedulerTillDateValidationMessage',
        function (value, context) {
          if (value === null) {
            return true;
          }
          if (typeof value == 'string') {
            const endTimeValueDate = new Date(value);
            const startTimeValue = dayjs(
              context.parent.attendeeList[0].startTime,
            );
            endTimeValueDate.setHours(startTimeValue.hour());
            endTimeValueDate.setMinutes(startTimeValue.minute());
            endTimeValueDate.setSeconds(startTimeValue.second());
            const endTimeValue = dayjs(endTimeValueDate);
            return startTimeValue.isBefore(endTimeValue);
          } else {
            return true;
          }
        },
      )
      .nullable(),
    otherwise: yup.string().nullable(),
  }),
  otherDescription: yup.string().when('appointmentType', {
    is: 'appointmentType10',
    then: yup.string().required('BlockingTime.descriptionError').nullable(),
    otherwise: yup.string().nullable(),
  }),
  attendeeList: yup.array().of(
    yup.object().shape({
      attendeeId: yup.string(),
      attendeeName: yup.string(),
      date: yup.string(),
      startTime: yup.date().required('startTimeRequiredMessage').nullable(),
      endTime: yup
        .date()
        .required('endTimeRequiredMessage')
        .test(
          'end-date-not-equal',
          'schedulerEndDateValidationMessage',
          function (value, context) {
            value?.setDate(context.parent.startTime?.getDate());
            return dayjs(context.parent.startTime).isBefore(dayjs(value));
          },
        )
        .nullable(),
      status: yup.string(),
    }),
  ),
  attendees: yup.array().when('clients', (clients, schema) => {
    return schema.min(1, 'AddNewSchedulerEvent.minAttendeeMessage').required();
  }),
});

type MultiSelectOptioinType = Employee | Client;

let selectedClients: MultiSelectOptioinType[] = [];
let selectedAttendees: MultiSelectOptioinType[] = [];

const AddNewSchedulerEventModal: FC<ModelPropType> = ({
  showModal,
  onModalClose,
  dateSelected,
  clients,
}) => {
  const loggedInClinicianName = localStorage.getItem(STORAGE_USERNAME_KEY);
  const rctl = useIntl();
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [appStartDate] = useState<DateString>(null);
  const [openAvailabilityCheckModel, setOpenAvailabilityCheckModel] =
    useState(false);
  const [highlightUnavailableAttendees, setHighlightUnavailableAttendees] =
    useState(false);
  const [unavailableAttendees, setUnavailableAttendees] = useState<string[]>(
    [],
  );
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [addAppointmentValues, setAddAppointmentValues] =
    useState<SchedulerFormikType>();
  const [openModal, setOpenModal] = useState(false);
  const [assesments, setAssessments] = useState('');
  const [commonDate, setCommonDate] = useState<string | null>(
    dateSelected ? dayjs(dateSelected.start).format() : null,
  );

  const prepareOwnerName = () => {
    if (loggedInClinicianName != undefined && loggedInClinicianName != '') {
      const [firstName, lastName] = loggedInClinicianName.split(' ');

      if (!firstName || !lastName) {
        return loggedInClinicianName;
      } else {
        return `${firstName} ${lastName[0].toUpperCase()}`;
      }
    }
  };

  const handleCreate = useCallback(
    debounce((values: SchedulerFormikType) => {
      toggleLoader(true);
      const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
      if (userId === null) {
        return;
      }
      if (values.recurringEvent) {
        const date = dayjs(values.frequencyEndDate).format('YYYY-MM-DD');
        const startTime = '00:00:00';
        const timeZOne = dayjs(values.frequencyEndDate).format('Z');
        const endDT = dayjs.utc(`${date} ${startTime}${timeZOne}`);
        values.endDateTime = endDT.format();
        values.frequency = values.frequency.toLowerCase();
      }
      values.owner = userId;
      values.attendeeList.forEach((attendee) => {
        const date = dayjs(attendee.date).format('YYYY-MM-DD');
        const startTime = dayjs(attendee.startTime).format('HH:mm:ss');
        const endTime = dayjs(attendee.endTime).format('HH:mm:ss');
        const timeZOne = dayjs(attendee.date).format('Z');

        const newStartTime = dayjs.utc(`${date} ${startTime}${timeZOne}`);
        const newEndTime = dayjs.utc(`${date} ${endTime}${timeZOne}`);

        if (newEndTime.isBefore(newStartTime)) {
          attendee.startTime = newStartTime.format();
          attendee.endTime = newEndTime.add(1, 'day').format();
        } else {
          attendee.startTime = newStartTime.format();
          attendee.endTime = newEndTime.format();
        }
      });
      values.occurrences.forEach((occurrence) => {
        occurrence.attendeeList.forEach((attendee) => {
          const date = dayjs(attendee.date).format('YYYY-MM-DD');
          const startTime = dayjs(attendee.startTime).format('HH:mm:ss');
          const endTime = dayjs(attendee.endTime).format('HH:mm:ss');
          const timeZOne = dayjs(attendee.date).format('Z');

          const newStartTime = dayjs.utc(`${date} ${startTime}${timeZOne}`);
          const newEndTime = dayjs.utc(`${date} ${endTime}${timeZOne}`);

          if (newEndTime.isBefore(newStartTime)) {
            attendee.startTime = newStartTime.format();
            attendee.endTime = newEndTime.add(1, 'day').format();
          } else {
            attendee.startTime = newStartTime.format();
            attendee.endTime = newEndTime.format();
          }
        });
      });
      formHandleSubmit(values);
    }, 500),
    [],
  );

  useEffect(() => {
    if (commonDate) {
      const updatedAttendeeList =
        formRef.current &&
        formRef.current.values.attendeeList.map((attendee) => ({
          ...attendee,
          date: commonDate,
        }));
      if (formRef.current)
        formRef.current.setFieldValue('attendeeList', updatedAttendeeList);
    }
  }, [commonDate]);

  const formRef = React.useRef<FormikProps<SchedulerFormikType>>(null);
  console.log('formik error', formRef.current?.errors);

  const clearForm = () => {
    formRef.current?.setValues(formRef.current?.initialValues);
    selectedClients = [];
    selectedAttendees = [];
    setOpenModal(false);
  };

  useEffect(() => {
    getAttendeesList();
    return () => {
      toggleLoader(false);
      selectedClients = [];
      selectedAttendees = [];
      clearForm();
    };
  }, []);

  const getAttendeesList = () => {
    getAllEmployeeList(
      dayjs
        .utc(appStartDate === null ? dateSelected?.start : appStartDate)
        .format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z',
    )
      .then((response) => {
        selectedAttendees = [];
        setEmployees(response);
      })
      .catch(() => {});
  };

  const formHandleSubmit = (eventType: SchedulerFormikType) => {
    setHighlightUnavailableAttendees(false);
    setUnavailableAttendees([]);
    setAddAppointmentValues(eventType);

    checkAvailability(eventType, '')
      .then((response) => {
        toggleLoader(false);
        const attendees: string[] = [];
        if (response.attendeeList !== null) {
          response.attendeeList.forEach((attendee) => {
            if (
              attendee.unavailableStatus &&
              attendee.unavailableStatus !== null &&
              attendee.unavailableStatus === true
            ) {
              attendees.push(attendee.attendeeId);
            }
          });
        }
        if (attendees.length != 0) {
          setOpenAvailabilityCheckModel(true);
          setUnavailableAttendees(attendees);
          return;
        } else {
          saveAppointment(eventType);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          onModalClose(
            true,
            'error',
            rctl.formatMessage({ id: apiError.id }),
            undefined,
            undefined,
          );
        } else {
          onModalClose(true, 'error', error, undefined, undefined);
        }
      });
  };

  const saveAppointment = (eventType: SchedulerFormikType): void => {
    toggleLoader(true);
    setHighlightUnavailableAttendees(false);
    setUnavailableAttendees([]);
    addAppointment(eventType)
      .then(() => {
        onModalClose(
          true,
          'success',
          undefined,
          'AddNewSchedulerEvent.successMessage',
          'Appointment added successfully.',
        );
      })
      .catch((error) => {
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          onModalClose(
            true,
            'error',
            rctl.formatMessage({ id: apiError.id }),
            undefined,
            undefined,
          );
        } else {
          const errorMessage = error as ApiMessage;
          if (errorMessage.code === 1001) {
            onModalClose(
              true,
              'error',
              undefined,
              'createAppointmentError',
              error,
            );
          } else {
            onModalClose(true, 'error', error, undefined, undefined);
          }
        }
      });
  };

  const closeUnavailabilityModal = (
    justCloseWithoutAnySelection: boolean,
    proceedWithUnavailabilites: boolean,
  ) => {
    if (!justCloseWithoutAnySelection) {
      if (proceedWithUnavailabilites && addAppointmentValues) {
        saveAppointment(addAppointmentValues);
      } else {
        setHighlightUnavailableAttendees(true);
      }
    }
    setOpenAvailabilityCheckModel(false);
  };

  const getFrequencyText = (
    frequencyEndDate: string | null,
    frequency: string,
  ) => {
    if (frequency === 'Weekly' && frequencyEndDate !== null) {
      // return Weekly on 'The week day' based on the frequency end date
      return `Weekly on ${dayjs(frequencyEndDate).format('dddd')}`;
    } else if (frequency === 'Biweekly' && frequencyEndDate !== null) {
      // return Weekly on 'The week day' based on the frequency end date
      return `Biweekly on ${dayjs(frequencyEndDate).format('dddd')}`;
    } else if (frequency === 'Monthly' && frequencyEndDate !== null) {
      // return Monthly on 'The date' based on the frequency end date
      return `Monthly on ${dayjs(frequencyEndDate).format('DD')}`;
    } else if (frequency === 'Yearly' && frequencyEndDate !== null) {
      // return Yearly on 'The date' based on the frequency end date
      return `Yearly on ${dayjs(frequencyEndDate).format('DD MMM')}`;
    }
    return '';
  };

  const getClientAddress = useCallback((client: Client): string => {
    const addressParts = [
      client.eStreet,
      client.eAddress2,
      client.eCity,
      client.eState,
      client.eZipCode !== null ? (client.eZipCode as string) : '',
    ]
      .filter(Boolean)
      .join(', ');
    return addressParts;
  }, []);

  return (
    <>
      <Divider sx={{ bgcolor: '#CFDEDE' }} />
      {addAppointmentValues && (
        <UnavailabilityCheckModal
          openAvailabilityCheckModel={openAvailabilityCheckModel}
          closeUnavailabilityModal={closeUnavailabilityModal}
        ></UnavailabilityCheckModal>
      )}
      <ModalPopup
        open={openModal}
        description="formUnsavedChangesMessage"
        onCancel={() => setOpenModal(false)}
        onOk={() => {
          clearForm();
        }}
        labelId1="Clientpage.cancelbtn"
        negativeActionLabel="Cancel"
        labelId2="Clientpage.Okbtn"
        positiveActionLabel="Ok"
      />
      <Drawer
        sx={{
          width: '35%',
          paddingInline: '24px',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        anchor={'right'}
        open={showModal}
        onClose={() => {
          onModalClose(false, undefined, undefined, undefined, undefined);
        }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: '#E7F0F0',
            padding: '0px 0px 0px 0px !important',
            backgroundColor: '#fff',
            pointerEvents: 'auto',
          }}
        >
          <Card
            sx={{
              my: '0px',
              ml: '0px',
              mt: '0px',
              padding: '24px',
              boxShadow: 'none',
            }}
          >
            <Formik
              innerRef={formRef}
              initialValues={{
                location: '',
                description: '',
                otherDescription: null,
                attendeeList: [],
                appointmentType: '',
                clients: [],
                attendees: [],
                frequency: '',
                frequencyEndDate: null,
                occurrences: [],
                recurringEvent: false,
                owner: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleCreate(JSON.parse(JSON.stringify(values)));
              }}
            >
              {({
                setFieldValue,
                handleSubmit,
                initialValues,
                values,
                errors,
                touched,
              }) => (
                <Grid container style={{ gap: 16 }}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={11}>
                      <MediumTypography
                        className="mainText-lg"
                        labelid="AddNewSchedulerEvent.AddAppointmentTitle"
                        defaultlabel="Create an Appointment"
                      />
                    </Grid>
                    <Grid item xs={1} textAlign={'right'}>
                      <IconButton
                        onClick={() => {
                          onModalClose(
                            false,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                          );
                        }}
                      >
                        <Closegray />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container direction="row">
                    <Grid item xs={1} className="svgIcon">
                      <Menu />
                    </Grid>
                    <Grid item xs={11}>
                      <DropdownComponent
                        optionalBadge={true}
                        names={appointmentTypes}
                        labelid="AddNewSchedulerEvent.appointmentType"
                        defaultlabelid="Appointment type"
                        name="appointmentType"
                        value={
                          assesments !== ''
                            ? assesments
                            : values.appointmentType
                        }
                        handleChange={(value) => {
                          if (value == 'appointmentTypeCombined') {
                            selectedClients = [];
                            selectedAttendees = [];
                            formRef.current?.setValues({
                              ...values,
                              appointmentType: '',
                              clients: [],
                              location: '',
                              description: '',
                              attendeeList: [],
                              attendees: [],
                              frequency: '',
                              occurrences: [],
                              otherDescription: '',
                              owner: '',
                              recurringEvent: false,
                            });
                            setAssessments(value);
                            return;
                          } else {
                            selectedClients = [];
                            selectedAttendees = [];
                            formRef.current?.setValues({
                              ...values,
                              appointmentType: value,
                              clients: [],
                              location: '',
                              description: '',
                              attendeeList: [],
                              attendees: [],
                              frequency: '',
                              occurrences: [],
                              otherDescription: '',
                              owner: '',
                              recurringEvent: false,
                            });
                            setAssessments('');
                          }
                        }}
                      />

                      {assesments == 'appointmentTypeCombined' && (
                        <CustomRadioButton
                          className="mt-md"
                          data={[
                            {
                              value: 'appointmentType2',
                              labelId: 'Initial',
                              defaultLabel: 'Initial',
                            },
                            {
                              value: 'appointmentType3',
                              labelId: 'Follow up',
                              defaultLabel: 'Follow up',
                            },
                          ]}
                          value={values.appointmentType}
                          onSelectItem={(value) => {
                            selectedClients = [];
                            selectedAttendees = [];
                            formRef.current?.setValues({
                              ...values,
                              appointmentType: value ? value : '',
                              clients: [],
                              location: '',
                              description: '',
                              attendeeList: [],
                              attendees: [],
                              frequency: '',
                              occurrences: [],
                              otherDescription: '',
                              owner: '',
                              recurringEvent: false,
                            });
                          }}
                          row={false}
                        />
                      )}
                      {errors.appointmentType && touched.appointmentType && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.appointmentType}
                          defaultlabel={'Appointment type is required'}
                        />
                      )}
                      {values.appointmentType == 'appointmentType10' && (
                        <Textinput
                          className="mt-lg"
                          labelid="description"
                          Required
                          defaultlabelid="Description*"
                          inputProps={{ maxLength: 30 }}
                          handlechange={(value) =>
                            setFieldValue('otherDescription', value)
                          }
                        />
                      )}
                      {errors.otherDescription && touched.otherDescription && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.otherDescription}
                          defaultlabel={'Description is required'}
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Grid container direction="row">
                    <Grid item xs={1} className="svgIcon"></Grid>
                    <Grid item xs={11} className="multisearchHgt">
                      <DatePickerComponent
                        labelid="IFSP.Date"
                        defaultlabelid="Date *"
                        required={true}
                        value={commonDate ? dayjs(commonDate) : null}
                        disabledDate={false}
                        handlechange={(date: Dayjs | null) => {
                          const formattedDate =
                            dayjs(date).format('MM/DD/YYYY');
                          setCommonDate(formattedDate);
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container direction="row">
                    <Grid item xs={1} className="svgIcon">
                      <Users />
                    </Grid>
                    <Grid item xs={11} className="multisearchHgt">
                      <MultiSelectAutocompleteSearch
                        labelId="AddNewSchedulerEvent.clients"
                        defaultId="Add Client(s)"
                        value={selectedClients}
                        onSelectItem={(value: MultiSelectOptioinType[]) => {
                          if (
                            !appointmentTypesForMultiClients.includes(
                              values.appointmentType,
                            )
                          ) {
                            if (value.length == 0) {
                              setFieldValue('clients', []);
                              selectedClients = [];
                            } else if (value.length == 1) {
                              setFieldValue(
                                'clients',
                                value.map((client) => client.clientId),
                              );
                              selectedClients = value;
                            } else {
                              selectedClients = [value[value.length - 1]];
                              setFieldValue(
                                'clients',
                                selectedClients.map(
                                  (client) => client.clientId,
                                ),
                              );
                            }
                          } else {
                            selectedClients = value;
                            setFieldValue(
                              'clients',
                              value.map((client) => client.clientId),
                            );
                          }
                          if (
                            values.appointmentType === 'appointmentType1' ||
                            values.appointmentType === 'appointmentType2' ||
                            values.appointmentType === 'appointmentType3' ||
                            values.appointmentType === 'appointmentType4' ||
                            values.appointmentType === 'appointmentType11' ||
                            values.appointmentType === 'appointmentType5'
                          ) {
                            const theSelectedClient: Client | null =
                              selectedClients.length > 0
                                ? (selectedClients[0] as Client)
                                : null;
                            if (
                              theSelectedClient !== null &&
                              theSelectedClient.weapons === true &&
                              theSelectedClient.weaponSecure === false
                            ) {
                              setFieldValue('location', '');
                              setFieldValue(
                                'description',
                                'Weapons are not secure!',
                              );
                            } else if (selectedClients.length > 0) {
                              setFieldValue(
                                'location',
                                getClientAddress(selectedClients[0] as Client),
                              );
                              setFieldValue('description', '');
                            } else if (value.length === 0) {
                              setFieldValue('description', '');
                              setFieldValue('location', '');
                            }
                          }
                        }}
                        data={clients}
                        optionHintId={
                          'AddNewSchedulerEvent.noClientsFoundInOptions'
                        }
                      />
                      {errors.clients && touched.clients && (
                        <SmallTypography
                          sxProps={{ color: 'red' }}
                          labelId={errors.clients as string}
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Grid container direction="row">
                    <Grid item xs={1} className="svgIcon">
                      <User />
                    </Grid>
                    <Grid item xs={11} className="multisearchHgt">
                      <MultiSelectAutocompleteSearch
                        labelId="AddNewSchedulerEvent.clinicians"
                        defaultId="Add Attendee(s)"
                        required
                        onSelectItem={(value: MultiSelectOptioinType[]) => {
                          let attendees = formRef.current?.values.attendeeList;
                          let occurrences = values.occurrences;
                          if (attendees) {
                            if (selectedAttendees.length > value.length) {
                              const att = selectedAttendees.find((attendee) => {
                                return !value.some((e) => attendee.id === e.id);
                              });
                              if (att) {
                                attendees = attendees.filter((attendee) => {
                                  return attendee.attendeeId !== att.id;
                                });
                                if (values.recurringEvent) {
                                  occurrences = occurrences.map(
                                    (occurrence) => {
                                      occurrence.attendeeList =
                                        occurrence.attendeeList.filter(
                                          (attendee) =>
                                            attendee.attendeeId !== att.id,
                                        );
                                      return occurrence;
                                    },
                                  );
                                }
                              }
                            } else {
                              if (value.length === 0) {
                                attendees = [];
                                return;
                              }
                              const att = value.find((attendee) => {
                                return !selectedAttendees.some(
                                  (e) => attendee.id === e.id,
                                );
                              });
                              if (att) {
                                attendees.push({
                                  attendeeId: att.id as string,
                                  attendeeName: `${att.firstName} ${att.lastName}`,
                                  date: commonDate,
                                  startTime: dateSelected
                                    ? dayjs(dateSelected.start).format()
                                    : null,
                                  endTime: dateSelected
                                    ? dayjs(dateSelected.end).format()
                                    : null,
                                  status: 'CREATE',
                                  appointmentId: '',
                                  allowUpdateDeleteActions: true,
                                });
                                if (values.recurringEvent) {
                                  occurrences = occurrences.map(
                                    (occurrence) => {
                                      const occurranceLength =
                                        occurrence.attendeeList.length;
                                      if (occurranceLength === 0) {
                                        occurrence.attendeeList.push({
                                          attendeeId: att.id as string,
                                          attendeeName: `${att.firstName} ${att.lastName}`,
                                          date: commonDate,
                                          startTime: dateSelected
                                            ? dayjs(dateSelected.start).format()
                                            : null,
                                          endTime: dateSelected
                                            ? dayjs(dateSelected.start)
                                                .add(60, 'minute')
                                                .format()
                                            : null,
                                          status: 'CREATE',
                                          appointmentId: '',
                                          allowUpdateDeleteActions: true,
                                        });
                                      } else {
                                        const lastAttendee =
                                          occurrence.attendeeList[
                                            occurranceLength - 1
                                          ];
                                        occurrence.attendeeList.push({
                                          attendeeId: att.id as string,
                                          attendeeName: `${att.firstName} ${att.lastName}`,
                                          date: commonDate,
                                          startTime: dayjs(
                                            lastAttendee.startTime,
                                          )
                                            .add(1, 'day')
                                            .format(),
                                          endTime: dayjs(lastAttendee.endTime)
                                            .add(60, 'minute')
                                            .format(),
                                          status: 'CREATE',
                                          appointmentId: '',
                                          allowUpdateDeleteActions: true,
                                        });
                                      }
                                      return occurrence;
                                    },
                                  );
                                }
                              }
                            }
                          } else {
                            attendees = [
                              {
                                attendeeId: value[0].id as string,
                                attendeeName: `${value[0].firstName} ${value[0].lastName}`,
                                date: commonDate,
                                startTime: dateSelected
                                  ? dayjs(dateSelected.start).format()
                                  : null,
                                endTime: dateSelected
                                  ? dayjs(dateSelected.end).format()
                                  : null,
                                status: 'CREATE',
                                appointmentId: '',
                                allowUpdateDeleteActions: true,
                              },
                            ];
                          }
                          selectedAttendees = value;
                          formRef.current?.setValues({
                            ...values,
                            attendees: value.map(
                              (employee) => employee.id as string,
                            ),
                            attendeeList: attendees,
                            occurrences: occurrences,
                            recurringEvent:
                              value.length === 0
                                ? false
                                : values.recurringEvent,
                          });
                        }}
                        value={selectedAttendees}
                        highlightSelectedValues={
                          highlightUnavailableAttendees
                            ? unavailableAttendees
                            : []
                        }
                        highlightSelectedValuesTooltipLabelId="AddNewSchedulerEvent.unavailableAttendeesTooltip"
                        data={employees}
                        hideOption={null}
                        optionHintId={
                          'AddNewSchedulerEvent.noCliniciansFoundInOptions'
                        }
                      />
                      {errors.attendees && touched.attendees && (
                        <SmallTypography
                          sxProps={{
                            color: 'red',
                            '&::first-letter': {
                              textTransform: 'capitalize',
                            },
                          }}
                          labelId={errors.attendees as string}
                        />
                      )}
                    </Grid>
                  </Grid>

                  {values.attendeeList &&
                    values.attendeeList.length > 0 &&
                    values.attendeeList.map((attendee, index) => {
                      return (
                        <>
                          <Grid
                            container
                            xs={12}
                            direction="row"
                            alignItems={'end'}
                            columnSpacing={'12px'}
                          >
                            <Grid item xs={1} className="svgIcon">
                              <Clock />
                            </Grid>
                            <Grid item xs={3.8} className="hide">
                              <MediumTypography
                                sxProps={{ marginBottom: '4px' }}
                                label={attendee.attendeeName}
                              />
                              <DatePickerComponent
                                labelid="IFSP.Date"
                                defaultlabelid="Date *"
                                required={true}
                                value={
                                  attendee.date !== null
                                    ? dayjs(attendee.date)
                                    : null
                                }
                                disabledDate={true}
                                handlechange={(date: Dayjs | null) => {
                                  const formattedDate =
                                    dayjs(date).format('MM/DD/YYYY');
                                  setFieldValue(
                                    `attendeeList[${index}].date`,
                                    formattedDate,
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={3.6}>
                              <MediumTypography
                                sxProps={{
                                  marginBottom: '4px',
                                  width: 'max-content',
                                }}
                                label={attendee.attendeeName}
                              />
                              <AppTimePicker
                                name="Start Time"
                                value={
                                  values.attendeeList[index].startTime !== null
                                    ? dayjs(
                                        values.attendeeList[index].startTime,
                                      )
                                    : null
                                }
                                minuteStep={1}
                                disable={
                                  values.attendeeList[index].date !== null
                                    ? false
                                    : true
                                }
                                onTimeChange={(time) => {
                                  if (time === null) {
                                    values.attendeeList[index].startTime = null;
                                    values.attendeeList[index].endTime = null;
                                  } else {
                                    values.attendeeList[index].startTime =
                                      time.format();
                                    values.attendeeList[index].endTime = null;
                                  }
                                  formRef.current?.setValues(values, true);
                                }}
                              />
                            </Grid>
                            <Grid item xs={3.6}>
                              <AppTimePicker
                                name="End Time"
                                disable={
                                  values.attendeeList[index].startTime !== null
                                    ? false
                                    : true
                                }
                                value={
                                  values.attendeeList[index].endTime !== null
                                    ? dayjs(values.attendeeList[index].endTime)
                                    : null
                                }
                                minuteStep={1}
                                onTimeChange={(time) => {
                                  values.attendeeList[index].endTime =
                                    time === null ? null : time.format();

                                  formRef.current?.setValues(values);
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}></Grid>

                            <>
                              <Grid
                                item
                                xs={3.6}
                                style={{ marginLeft: '50px', width: '100px' }}
                              >
                                {errors.attendeeList &&
                                  touched.attendeeList &&
                                  (
                                    errors.attendeeList as FormikErrors<SchedulerAttendee>[]
                                  )[index] &&
                                  (
                                    errors.attendeeList as FormikErrors<SchedulerAttendee>[]
                                  )[index].startTime && (
                                    <MediumTypography
                                      labelid={
                                        (
                                          errors.attendeeList as FormikErrors<SchedulerAttendee>[]
                                        )[index].startTime
                                      }
                                      defaultlabel="Start or End time is required"
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={3.6}>
                                {errors.attendeeList &&
                                  touched.attendeeList &&
                                  (
                                    errors.attendeeList as FormikErrors<SchedulerAttendee>[]
                                  )[index] &&
                                  (
                                    errors.attendeeList as FormikErrors<SchedulerAttendee>[]
                                  )[index].endTime && (
                                    <MediumTypography
                                      labelid={
                                        (
                                          errors.attendeeList as FormikErrors<SchedulerAttendee>[]
                                        )[index].endTime
                                      }
                                      defaultlabel="End date must be after start date"
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>
                            </>
                          </Grid>
                        </>
                      );
                    })}

                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={1} className="svgIcon"></Grid>
                    <Grid item container xs={11}>
                      <CheckBoxComponent
                        name="recurringEvent"
                        disabled={
                          values.attendeeList.length < 1 ||
                          (values.attendeeList[0].date !== null &&
                            new Date(values.attendeeList[0].date).getDay() ===
                              0) ||
                          (values.attendeeList[0].date !== null &&
                            new Date(values.attendeeList[0].date).getDay() ===
                              6)
                            ? true
                            : false
                        }
                        defaultlabelid="Recurring Event"
                        labelid="AddNewSchedulerEvent.recurringEvent"
                        ischecked={values.recurringEvent}
                        CheckHandleChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          setFieldValue('recurringEvent', e.target.checked);

                          if (e.target.checked) {
                            setFieldValue('frequency', 'Daily');
                            const attendees = values.attendeeList;
                            const initialoccurrences: ScheduleFormikOccurance[] =
                              [];
                            const attendeeList: SchedulerAttendee[] = [];
                            attendees.forEach((attendee) => {
                              attendeeList.push({
                                attendeeName: attendee.attendeeName,
                                attendeeId: attendee.attendeeId,
                                date: dayjs(attendee.date).format(),
                                startTime: attendee.startTime,
                                endTime: attendee.endTime,
                                status: 'CREATE',
                                appointmentId: '',
                                allowUpdateDeleteActions: true,
                              });
                            });
                            initialoccurrences.push({
                              occurrenceId: 1,
                              attendeeList: attendeeList,
                            });

                            setFieldValue('occurrences', initialoccurrences);
                          } else {
                            setFieldValue('frequency', '');
                          }
                        }}
                      />
                    </Grid>
                  </Grid>

                  {values.recurringEvent && (
                    <Box>
                      <Grid container direction="row" alignItems="center">
                        <Grid item xs={1} className="svgIcon"></Grid>
                        <Grid item container xs={11} alignItems="flex-start">
                          <Grid item xs={4.5} marginLeft={'14px'}>
                            <SelectComponent
                              labelid=""
                              defaultlabelid=""
                              name={'frequency'}
                              handleChange={(value: string) => {
                                const attendees = values.attendeeList;
                                const initialoccurrences: ScheduleFormikOccurance[] =
                                  [];
                                Array.from({ length: parseInt('1') }, () => {
                                  const attendeeList: SchedulerAttendee[] = [];
                                  attendees.forEach((attendee) => {
                                    attendeeList.push({
                                      attendeeName: attendee.attendeeName,
                                      attendeeId: attendee.attendeeId,
                                      date: dayjs(attendee.date).format(),
                                      startTime: attendee.startTime,
                                      endTime: attendee.endTime,
                                      status: 'CREATE',
                                      appointmentId: '',
                                      allowUpdateDeleteActions: true,
                                    });
                                  });
                                  initialoccurrences.push({
                                    occurrenceId: 1,
                                    attendeeList: attendeeList,
                                  });
                                });
                                setFieldValue(
                                  'occurrences',
                                  initialoccurrences,
                                );
                                setFieldValue('frequency', value);
                              }}
                              value={values.frequency}
                              names={SchedulerFrequency}
                            />
                            {errors.frequency && touched.frequency && (
                              <SmallTypography
                                sxProps={{
                                  color: 'red',
                                  textAlign: 'left',
                                  marginTop: '8px',
                                }}
                                labelId={errors.frequency}
                              />
                            )}
                          </Grid>

                          <Grid item xs={6} marginLeft={'15px'}>
                            <DatePickerComponent
                              labelid="IFSP.Till"
                              defaultlabelid="Till Date"
                              required={true}
                              minDate={dayjs(values.attendeeList[0].date)}
                              value={dayjs(values.frequencyEndDate)}
                              disabledDate={false}
                              handlechange={(date: Dayjs | null) => {
                                if (date === null) {
                                  setFieldValue(`frequencyEndDate`, null);
                                  return;
                                }
                                const formattedDate =
                                  dayjs(date).format('MM/DD/YYYY');
                                setFieldValue(
                                  `frequencyEndDate`,
                                  formattedDate,
                                );
                              }}
                            />
                            {errors.frequencyEndDate &&
                              touched.frequencyEndDate && (
                                <MediumTypography
                                  sxProps={{
                                    color: 'red',
                                    textAlign: 'left',
                                    marginTop: '8px',
                                  }}
                                  labelid={errors.frequencyEndDate}
                                  className="errorText-md"
                                />
                              )}
                          </Grid>

                          {values.frequencyEndDate !== null &&
                            values.frequency !== 'Daily' && (
                              <Grid item xs={6} marginLeft={'14px'}>
                                <MediumTypography
                                  label={getFrequencyText(
                                    values.attendeeList[0].date,
                                    values.frequency,
                                  )}
                                  className="text-md"
                                />
                              </Grid>
                            )}
                        </Grid>
                      </Grid>
                    </Box>
                  )}

                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={1} className="svgIcon">
                      <MapPin />
                    </Grid>
                    <Grid item xs={11}>
                      <Textinput
                        name="location"
                        labelid="AddNewSchedulerEvent.location"
                        defaultlabelid="Location"
                        Value={values.location}
                        inputProps={{
                          maxLength: 300,
                        }}
                        Required={values.clients.length >= 1}
                        handlechange={(value: string) => {
                          setFieldValue('location', value);
                        }}
                      />

                      {errors.location && touched.location && (
                        <MediumTypography
                          labelid={errors.location}
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={1} className="svgIcon">
                      <AlignLeft />
                    </Grid>
                    <Grid item xs={11}>
                      <Textinput
                        name="description"
                        Value={values.description}
                        labelid="AddNewSchedulerEvent.notes"
                        defaultlabelid="Add Notes"
                        inputProps={{
                          maxLength: 500,
                        }}
                        handlechange={(value: string) => {
                          setFieldValue('description', value);
                        }}
                      />
                      {errors.description && touched.description && (
                        <MediumTypography
                          labelid={errors.description}
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>
                  {loggedInClinicianName != undefined && (
                    <Grid container direction="row" alignItems="center">
                      <Grid item xs={1} className="svgIcon">
                        <Calender />
                      </Grid>
                      <Grid item xs={11}>
                        <span
                          style={{
                            color: '#2A4241',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '18px',
                            fontFamily: 'Lato-regular',
                          }}
                        >
                          {' '}
                          {prepareOwnerName()}{' '}
                        </span>
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    container
                    direction="row"
                    alignItems="right"
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Grid item sx={{ marginRight: '16px' }}>
                      <ButtonComponent
                        className="btn-primary btn-cancel"
                        variantType="contained"
                        labelId="AddNewSchedulerEvent.cancelButton"
                        defaultLabelId="Cancel"
                        onClick={() => {
                          if (_.isEqual(initialValues, values)) {
                            onModalClose(
                              false,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                            );
                          } else {
                            setOpenModal(true);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        type="submit"
                        labelId="AddNewSchedulerEvent.submitButton"
                        defaultLabelId="Create"
                        loading={false}
                        onClick={() => {
                          handleSubmit();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Formik>
          </Card>
        </Box>
      </Drawer>
    </>
  );
};

export default AddNewSchedulerEventModal;
